import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SearchQueries from "../components/SearchQueries";

import useReactRouter from "use-react-router";

function SearchResult() {
  const {
    match: {
      params: { query },
    },
  } = useReactRouter();
  return (
    <>
      <Header />
      <div className="page-body">
        <SearchQueries query={query} />
      </div>
      <Footer />
    </>
  );
}

export default SearchResult;
