import React from "react";
import ProfileBody from "../components/ProfileBody";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Profile() {
  return (
    <>
      <Header />
      <div className="page-body">
        <ProfileBody />
      </div>
      <Footer />
    </>
  );
}

export default Profile;
