import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SigninBody from '../components/SigninBody';

function Signin() {
  return (
    <>
      <Header />
      <div className="page-body"><SigninBody /></div>
      <Footer />
    </>
  );
}

export default Signin;