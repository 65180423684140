import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import DummyImage from "./DummyImage.json";
import { useContext } from "react";
import { Store } from "../Store";
import { database } from "../stitch";
import { ObjectId } from "bson";
import { Link } from "react-router-dom";
import { TryFollow } from "../component/TryFollow";

function FollowerModal({
  isMyProfile,
  onClose,
  selectedProfile,
  setSelectedProfile,
  setIsFollowerShown,
}) {
  const { profile, setStore } = useContext(Store);

  const { followers } = selectedProfile;
  const [search, setSearch] = useState(" ");
  const [searchResults, setSearchResults] = useState(followers);
  const [enableTryModal, setEnableTryModal] = useState(false);
  const [tryUserName, setTryUserName] = useState("");
  // const [followings, setFollowings] = useState(false);

  //console.log("selectedProfile of Profile Oject >>", selectedProfile);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);

    // Filter data based on the search query
    setTimeout(() => {
      if (search.length > 0) {
        const filteredResults = followers.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.userName.toLowerCase().includes(search.toLowerCase())
        );
        setSearchResults(filteredResults);
      } else {
        setSearchResults(followers);
      }
    }, 500);
  };

  const isUserFollowing = (userObj) => {
    let followbool, boolObj;

    // if (profile && profile.following) {
    //   for (const item of profile.following) {
    //     if (item?.userid?.toString() === userObj?.userid?.toString()) {
    //       return true; // User is following
    //     }
    //   }
    // }
    // return false; // User is not following

    if (profile?.followers?.length > 0) {
      const tempObj = profile?.followers?.filter(
        (item) => item.userid?.toString() === userObj?.userid.toString()
      ).length;
      //console.log("tempObj>>>>>>>>>>>>>>>>>>", tempObj);
      followbool = tempObj > 0 ? true : false;
      //console.log("followbool>>>>>>>>>>.>>", followbool);
      //setFollowing(followbool);
    } else {
      //setFollowing(false);
      followbool = false;
    }

    if (profile?.following?.length > 0) {
      const tempIsBackFollowingObj = profile?.following?.filter(
        (item) => item.userid?.toString() === userObj?.userid.toString()
      ).length;
      boolObj = tempIsBackFollowingObj > 0 ? true : false;
      //console.log("backFollow>>>", boolObj);
      //setIsBackFollowing(boolObj);
    } else {
      boolObj = false;
      //setIsBackFollowing(false);
    }

    return followbool && boolObj ? 1 : !followbool && followbool ? 2 : 3;
  };

  // const removeFollowerClickHandler = async (userObj) => {
  //   if (profile) {
  //     const tempObj = profile?.followers?.filter(
  //       (item) => item.userid.toString() !== userObj.userid.toString()
  //     );
  //     profile.followers = tempObj;
  //     selectedProfile.followers = tempObj;
  //     setSelectedProfile(selectedProfile);
  //     setSearchResults(selectedProfile.followers);
  //     setStore(profile);
  //     await database
  //       .collection("profiles")
  //       .updateOne(
  //         { _id: ObjectId(profile._id) },
  //         { $set: { followers: tempObj } }
  //       );
  //     await database
  //       .collection("profiles")
  //       .updateOne(
  //         { _id: ObjectId(userObj.userid) },
  //         { $pull: { following: { userid: ObjectId(profile._id) } } }
  //       );
  //   } else {
  //     ///alert("Please Sign in / Sign up to your account to access profile.");
  //     const tempusernme = userObj && userObj.name;
  //     setEnableTryModal(true);
  //     setTryUserName(tempusernme);
  //     return false;
  //   }
  // };

  const ProfileFollowBackUser = async (userObj) => {
    // console.log("selectedProfile >>>", selectedProfile);
    // console.log("active profile  >>>", profile);

    // const saveObj = {
    //   userid: ObjectId(userObj._id),
    //   name: selectedProfile.firstName + " " + selectedProfile.lastName, //selectedProfile.firstName,
    //   userName: selectedProfile.userName,
    //   image: selectedProfile.image,
    //   createat: new Date(),
    // };
    let tempObj = profile?.following?.length > 0 ? [...profile?.following] : [];
    if (
      profile?.following?.filter(
        (item) => item.userid?.toString() === userObj?.userid?.toString()
      ).length === 0
    ) {
      tempObj = [...tempObj, userObj];
    } else {
      tempObj =
        profile?.following?.length > 0
          ? profile?.following?.filter(
              (item) => item.userid?.toString() !== userObj?.userid?.toString()
            )
          : [userObj];
    }
    profile.following = tempObj;

    //selectedProfile.followers = tempObj;
    //setSelectedProfile(selectedProfile);
    setStore(profile);
    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(profile._id) },
        { $set: { following: tempObj } }
      );
  };

  const SearchProfileFollowBack = async (userObj) => {
    const tempUserObj = await database
      .collection("profiles")
      .findOne({ _id: ObjectId(userObj.userid) });
    ///console.log("tempUserObj >>>", tempUserObj);
    if (tempUserObj) {
      const saveObj = {
        userid: ObjectId(profile._id),
        name: profile.firstName + " " + profile.lastName, //loginProfile.firstName,
        userName: profile.userName,
        image: profile.image,
        createat: new Date(),
      };
      let tempObj =
        tempUserObj?.followers?.length > 0 ? [...tempUserObj?.followers] : [];
      if (
        tempUserObj?.followers?.filter(
          (item) => item.userid?.toString() === profile._id?.toString()
        ).length === 0
      ) {
        tempObj = [...tempObj, saveObj];
      } else {
        tempObj =
          tempUserObj?.followers?.length > 0
            ? tempUserObj?.followers?.filter(
                (item) => item.userid?.toString() !== profile._id?.toString()
              )
            : [saveObj];
      }
      tempUserObj.followers = tempObj;
      //setSelectedProfile(selectedProfile);
      await database
        .collection("profiles")
        .updateOne(
          { _id: ObjectId(userObj.userid) },
          { $set: { followers: tempObj } }
        );
    }

    // const saveObj = {
    //   userid: ObjectId(profile._id),
    //   name: profile.firstName + " " + profile.lastName, //loginProfile.firstName,
    //   userName: profile.userName,
    //   image: profile.image,
    //   createat: new Date(),
    // };
    // let tempObj =
    //   selectedProfile?.followers?.length > 0
    //     ? [...selectedProfile?.followers]
    //     : [];
    // if (
    //   selectedProfile?.followers?.filter(
    //     (item) => item.userid?.toString() === profile._id?.toString()
    //   ).length === 0
    // ) {
    //   tempObj = [...tempObj, saveObj];
    // } else {
    //   tempObj =
    //     selectedProfile?.followers?.length > 0
    //       ? selectedProfile?.followers?.filter(
    //           (item) => item.userid?.toString() !== profile._id?.toString()
    //         )
    //       : [saveObj];
    // }
    // selectedProfile.followers = tempObj;
    // setSelectedProfile(selectedProfile);
    // await database
    //   .collection("profiles")
    //   .updateOne(
    //     { _id: ObjectId(selectedProfile._id) },
    //     { $set: { followers: tempObj } }
    //   );
  };

  const followBackHandler = async (userObj, currentUserObj) => {
    //console.log("userObj  Means Other User>>> ", userObj);
    //console.log("currentUserObj  Means Login User>>> ", currentUserObj);
    if (profile) {
      await SearchProfileFollowBack(userObj);
      await ProfileFollowBackUser(userObj);
    } else {
      ///alert("Please Sign in / Sign up to your account to access profile.");
      const tempusernme = userObj && userObj.name;
      setEnableTryModal(true);
      setTryUserName(tempusernme);
      return false;
    }
  };

  // const AddFollowingHandler = async (userObj) => {
  //   const saveObj = {
  //     userid: ObjectId(profile._id),
  //     name: profile.firstName,
  //     userName: profile.userName,
  //     image: profile.image,
  //     createat: new Date(),
  //   };
  //   const tempObj = profile?.following?.filter(
  //     (item) => item.userid.toString() === userObj.userid.toString()
  //   );
  //   profile.following =
  //     tempObj.length > 0 ? profile.following : [...profile.following, userObj];
  //   selectedProfile.following = tempObj;
  //   setSelectedProfile(selectedProfile);
  //   // setSearchResults(selectedProfile.following);
  //   setStore(profile);
  //   await database
  //     .collection("profiles")
  //     .updateOne(
  //       { _id: ObjectId(profile._id) },
  //       { $set: { following: tempObj } }
  //     );
  //   await database
  //     .collection("profiles")
  //     .updateOne(
  //       { _id: ObjectId(userObj.userid) },
  //       { $push: { followers: saveObj } }
  //     );
  // };

  // const UnfollowHandler = async (userObj) => {
  //   const tempObj = profile?.following?.filter(
  //     (item) => item.userid?.toString() !== userObj.userid?.toString()
  //   );

  //   profile.following = tempObj;
  //   selectedProfile.following = tempObj;
  //   setSelectedProfile(selectedProfile);

  //   setStore(profile);

  //   await database
  //     .collection("profiles")
  //     .updateOne(
  //       { _id: ObjectId(profile._id) },
  //       { $pull: { following: tempObj } }
  //     );

  //   await database
  //     .collection("profiles")
  //     .updateOne(
  //       { _id: ObjectId(userObj.userid) },
  //       { $pull: { followers: { userid: ObjectId(profile._id) } } }
  //     );
  // };

  // console.log("followers>>>>>>>>", followers);
  // console.log("Search Result>>>>>>>", searchResults);

  const FollowingClickHandler = async (userObj) => {
    if (profile) {
      const isFollowing = profile?.following?.some(
        (item) => item.userid.toString() === userObj.userid.toString()
      );

      if (isFollowing) {
        // Unfollow user If his already follow
        // setFollowings(true);
        const tempObj = profile?.following?.filter(
          (item) => item.userid.toString() !== userObj.userid.toString()
        );

        profile.following = tempObj;
        selectedProfile.following = tempObj;

        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(profile._id) },
            { $set: { following: tempObj } }
          );
        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(userObj.userid) },
            { $pull: { followers: { userid: ObjectId(profile._id) } } }
          );
      } else {
        // Follow user If is not follow
        // setFollowings(false);
        const newFollowing = [...profile.following, userObj];
        profile.following = newFollowing;
        selectedProfile.following = newFollowing;

        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(profile._id) },
            { $set: { following: newFollowing } }
          );
        await database.collection("profiles").updateOne(
          { _id: ObjectId(userObj.userid) },
          {
            $push: {
              followers: {
                userid: ObjectId(profile._id),
                name: profile.firstName,
                userName: profile.userName,
                image: profile.image,
                createat: new Date(),
              },
            },
          }
        );
      }

      setSelectedProfile(selectedProfile);
      // setSearchResults(selectedProfile.following);
      setStore(profile);
    } else {
      //alert("Please Sign in / Sign up to your account to access profile.");
      const tempusernme = userObj && userObj.name;
      setEnableTryModal(true);
      setTryUserName(tempusernme);
      return false;
    }
  };

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>

        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-weight-bold">Follower</p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
            ></button>
          </header>
          <section className="modal-card-body">
            <p className="control has-icons-left">
              <input
                className="input"
                type="text"
                onChange={handleSearchChange}
                placeholder="Search"
              />
              <span className="icon is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
            <div className="following-list">
              {searchResults &&
                searchResults?.map((item, index) => {
                  // const btnlabl = isMyProfile ? "" : "";
                  const images =
                    item.image === ""
                      ? `data:image/png;base64,${DummyImage.data}`
                      : `data:image/png;base64,${item.image}`;
                  // console.log("image>>>>>>>", image);
                  return (
                    <div
                      key={index}
                      //className="is-flex is-justify-content-space-between is-align-content-center"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/${item.userName}`,
                          state: { id: item.userid },
                        }}
                      >
                        <div
                          onClick={() => setIsFollowerShown(false)}
                          className="profile-image is-flex is-align-content-center"
                        >
                          <img
                            src={images}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              height: "46px",
                              width: "46px",
                            }}
                          />
                          <div
                            className="profile-meta"
                            style={{ marginLeft: "10px" }}
                          >
                            <p className="has-text-weight-bold has-text-black wordwapUserName">
                              {item.name}
                            </p>
                            <p className="has-text-black wordwapUserName">
                              {item.userName}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {profile?._id?.toString() === item?.userid?.toString() ? (
                        <></>
                      ) : (
                        <>
                          {isMyProfile && (
                            <button
                              onClick={() => followBackHandler(item, profile)}
                              className={`button ${
                                isUserFollowing(item) === 1
                                  ? "postMessagebg"
                                  : "is-danger is-light"
                              } has-text-weight-bold`}
                              //className="button is-danger is-light has-text-weight-bold"
                              style={{
                                marginRight: "10px",
                                cursor: "pointer",
                              }}
                            >
                              {isUserFollowing(item) === 1
                                ? "Following"
                                : "Follow Back"}
                            </button>
                          )}
                          <>
                            <>
                              {!isMyProfile && (
                                <button
                                  onClick={() => FollowingClickHandler(item)}
                                  className={`button ${
                                    isUserFollowing(item) === 1
                                      ? "is-dark"
                                      : "postMessagebg"
                                  } has-text-weight-bold`}
                                  style={{
                                    marginRight: "10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {isUserFollowing(item) === 1
                                    ? "Following"
                                    : "Follow"}
                                </button>
                              )}
                            </>
                          </>
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </div>

      {enableTryModal && (
        <TryFollow
          enableTryModal={enableTryModal}
          setEnableTryModal={setEnableTryModal}
          userName={tryUserName}
          setTryUserName={setTryUserName}
        />
      )}
    </>
  );
}

export default FollowerModal;
