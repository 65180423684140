import React from 'react'
import "./Message.css";


const Message = ({ user ,message, classs }) => {
    // if (user) {
    //     return (
    //         <div className={`messageBox`} >{`${user} : ${message}`}</div>
    //     )
    // }
    // else {


    //     return (
    //         <div className={`messageBox`}>
    //             {`${message}`}
    //         </div>
    //     )
    // }
    return (
        <div className={`messageBox`} style={{flexDirection:'row'}}>
            {`${user} : ${message}`}
        </div>
    )
}

export default Message
