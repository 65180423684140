/* eslint-disable eqeqeq */
import React, { useState, useContext, useEffect } from "react";
//import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";
import clone from "lodash/clone";
//import { ObjectId } from "bson";
// import formatDate from 'date-fns/formatDate';
import { format } from "date-fns";
//import Timestamp from 'react-timestamp';
import { database } from "../stitch";
import EditProfileModal from "./EditProfileModal";
import EditActivityModal from "./EditActivityModal";
import DeleteActivityModal from "./DeleteActivityModal";
import Loading from "./Loading";
import DummyImage from "./DummyImage.json";
import { Store } from "../Store";
import { withTempLogin } from "../utils";
import ReactPlayer from "react-player/lazy";
//import { randomId } from '../utils';
//import PATHS from '../routePaths';
import VideoComponent from "./VideoComponent";
import ImageComponent from "./ImageComponent";
import PostMessage from "./PostMessage";
import FollowerModal from "./FollowerModal";
import FollowingModal from "./FollowingModal";
import FollowButton from "./Follow";
import SearchQueries from "./SearchQueries";
import TwitterVideoEmbed from "../component/TwitterEmbed";

function ProfileBody() {
  const { profile } = useContext(Store);
  //console.log("useReactRouter >>>", useReactRouter());

  let {
    history,
    location,
    match: {
      params: { id },
    },
  } = useReactRouter();

  const [isEditActivityShown, setIsEditActivityShown] = useState(false);
  const [isEditProfileShown, setIsEditProfileShown] = useState(false);
  const [isDeleteActivityShown, setIsDeleteActivityShown] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [curentTab, setCurentTab] = useState(1);
  const [currentindex, setCurrentindex] = useState(null);
  const [isFollowerShown, setIsFollowerShown] = useState(false);
  const [isFollowingShown, setIsFollowingShown] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isSearchShown, setisSearchShown] = useState(false);

  //const isMyProfile =
  ///  !state?.id || (profile && profile._id.toString() === state?.id);
  //const userId = location?.state?.id?.toString(); // using optional chaining to avoid potential errors
  //console.log("State >>> location >> ", history, userId[0]);

  const isMyProfile = !id || (profile && profile.userName === id);
  //console.log("isMyProfile >>", isMyProfile);

  useEffect(() => {
    const getProfile = async () => {
      if (isMyProfile) {
        setSelectedProfile(profile);
        setLoading(false);
      } else {
        const [record] = await Promise.all([
          withTempLogin(() => {
            return database.collection("profiles").findOne({ userName: id });
          }),
        ]);
        //console.log("record >>", record);
        if (!record) {
          history.push("/");
        }
        setSelectedProfile(record);
        setLoading(false);
      }
    };
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, profile, isMyProfile, selectedProfile, id]);

  if (loading) {
    return <Loading />;
  }
  if (!selectedProfile) {
    return null;
  }

  const sortedActivities =
    selectedProfile &&
    selectedProfile.activities &&
    selectedProfile.activities.length > 0
      ? clone(selectedProfile.activities).sort((a, b) =>
          new Date(a.activityDate) > new Date(b.activityDate) ? -1 : 1
        )
      : [];

  const image = `data:image/png;base64, ${
    selectedProfile.image || DummyImage.data
  }`;

  return (
    <div className="section">
      <div className="container">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-full">
            <div>
              <div className="user-profile-hd">
                <div className="user-profile-image">
                  <div className="user-avatar">
                    <div className="user-avatar-rounded">
                      <figure
                        className={`is-hidden-mobile image is-100x100-custom circleImg ${
                          curentTab === 1 && "circleImg"
                        }`}
                      >
                        <img src={image} alt={selectedProfile.firstName} />
                      </figure>
                      <figure
                        className={`is-hidden-tablet image is-100x100-custom circleImg ${
                          // Profile Image Changer
                          curentTab === 1 && "circleImg"
                        }`}
                      >
                        <img src={image} alt={selectedProfile.firstName} />
                      </figure>
                    </div>

                    <div className="user-avatar-meta">
                      <div
                        className="h1 is-4"
                        style={{
                          fontSize: "1.5rem",
                          color: "#000",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        {selectedProfile.firstName +
                          " " +
                          selectedProfile.lastName}
                        {selectedProfile?.isverified && (
                          <span
                            className="icon has-text-success"
                            style={{ marginLeft: "15px" }}
                          >
                            {/* <i className="fas fa-check-square"></i> */}
                            <img
                              src={require("../../src/images/verifybedge.png")}
                              alt="verified"
                              style={{ width: "auto", height: "auto" }}
                            />
                          </span>
                        )}
                        {selectedProfile.showflag && selectedProfile.country ? (
                          selectedProfile.country.map((value, index) => {
                            return (
                              <img
                                key={index}
                                className="image-flag"
                                src={require(`../../public/assets/flags/${value.value}.png`)}
                                alt="country"
                              />
                            );
                          })
                        ) : (
                          <div></div>
                        )}
                      </div>
                      {/* {curentTab === 1 && ( */}
                      {selectedProfile?.userName && (
                        <div style={{ opacity: "1", marginBottom: "10px" }}>
                          {`@${selectedProfile?.userName}`}
                        </div>
                      )}
                      {/* )} */}
                      <div className="user-follow-count">
                        <div
                          //className="is-clickable"
                          onClick={
                            selectedProfile.hasOwnProperty("followers") &&
                            selectedProfile?.followers?.length > 0
                              ? () => setIsFollowerShown(true)
                              : () => {}
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            className="has-text-weight-bold"
                            //style={{ marginRight: "8px" }}
                          >
                            {selectedProfile.hasOwnProperty("followers")
                              ? selectedProfile?.followers?.length
                              : 0}
                          </span>
                          <span style={{ marginLeft: "5px" }}>Follower</span>
                        </div>
                        <div
                          onClick={
                            selectedProfile.hasOwnProperty("following") &&
                            selectedProfile?.following?.length > 0
                              ? () => setIsFollowingShown(true)
                              : () => {}
                          }
                          style={{
                            cursor: "pointer",
                            marginLeft: "8px",
                            marginRight: "8px",
                          }}
                        >
                          <span
                            className="has-text-weight-bold"
                            //style={{ marginRight: "8px" }}
                          >
                            {selectedProfile.hasOwnProperty("following")
                              ? selectedProfile?.following?.length
                              : 0}
                          </span>
                          <span style={{ marginLeft: "5px" }}>Following</span>
                        </div>
                        <div>
                          <span
                            className="has-text-weight-bold"
                            //style={{ marginRight: "8px" }}
                          >
                            {selectedProfile.hasOwnProperty("timelineMsg")
                              ? selectedProfile?.timelineMsg?.length
                              : 0}
                          </span>
                          <span style={{ marginLeft: "5px" }}>Post</span>
                        </div>
                      </div>

                      {selectedProfile.occupation && (
                        <div className="user-brif" style={{ color: "#000" }}>
                          <h4>{selectedProfile.occupation}</h4>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="user-follow-btn">
                    <div>
                      <FollowButton
                        isMyProfile={isMyProfile}
                        loginProfile={profile}
                        searchProfile={selectedProfile}
                        setSelectedProfile={setSelectedProfile}
                      />
                      {/* {!isMyProfile && (
                        <FollowButton
                          isMyProfile={isMyProfile}
                          loginProfile={profile}
                          searchProfile={selectedProfile}
                          setSelectedProfile={setSelectedProfile}
                        />
                      )} */}
                    </div>
                    {/* {profile === null ? (
                      <></>
                    ) : (
                      
                    )} */}
                  </div>
                </div>
                {/* <div className="user-follow-count">
                  <div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
                    <div className="column">
                      <div className="Click-Follower">
                        <h1 className="fllower-text">
                          {selectedProfile.hasOwnProperty("activities")
                            ? selectedProfile?.activities?.length
                            : 0}
                        </h1>
                        <h5>Posts</h5>
                      </div>
                    </div>
                    <div className="column">
                      <div
                        onClick={
                          selectedProfile.hasOwnProperty("followers") &&
                          selectedProfile?.followers?.length > 0
                            ? () => setIsFollowerShown(true)
                            : () => {}
                        }
                        className="Click-Follower"
                      >
                        <h1 className="fllower-text">
                          {selectedProfile.hasOwnProperty("followers")
                            ? selectedProfile?.followers?.length
                            : 0}
                        </h1>
                        <h5>Follower</h5>
                      </div>
                    </div>
                    <div className="column">
                      <div
                        onClick={
                          selectedProfile.hasOwnProperty("following") &&
                          selectedProfile?.following?.length > 0
                            ? () => setIsFollowingShown(true)
                            : () => {}
                        }
                        className="Click-Follower"
                      >
                        <h1 className="fllower-text">
                          {selectedProfile.hasOwnProperty("following")
                            ? selectedProfile?.following?.length
                            : 0}
                        </h1>
                        <h5>Following</h5>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {profile === null ? (
                  <></>
                ) : (
                  <>
                    
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
        <div className="border_mobile"></div>
        <div className="is-hidden-mobile spacer" />
        <div className="tab-container tab-menu">
          <ul>
            <li
              className={
                curentTab === 1 ? "tab-a active-a is-clickable" : "is-clickable"
              }
              style={{ cursor: "pointer" }}
              onClick={() => setCurentTab(1)}
            >
              <span>Brief</span>
            </li>
            <li
              className={
                curentTab === 0 ? "tab-a active-a is-clickable" : "is-clickable"
              }
              style={{ cursor: "pointer" }}
              onClick={() => setCurentTab(0)}
            >
              <span>Timeline</span>
            </li>
          </ul>
        </div>

        <div className="is-hidden-mobile " />
        {curentTab === 1 && (
          <div className="p-0" style={{ marginTop: "20px" }}>
            <PostMessage
              id={location?.state?.id}
              sortedActivities={selectedProfile}
              isMyProfile={isMyProfile}
              setSelectedProfile={setSelectedProfile}
              selectedProfiles={selectedProfile}
            />
          </div>
        )}
        {curentTab === 0 && (
          <div
            className="p-0"
            style={{
              display: "block",
              flexBasis: "0",
              flexGrow: "1",
              flexShrink: "1",
              marginTop: "20px",
            }}
          >
            {isMyProfile && (
              <div style={{ textAlign: "right", marginBottom: "30px" }}>
                <button
                  className="button is-rounded postMessagebg"
                  onClick={() => setIsEditActivityShown(true)}
                >
                  Add Timeline
                </button>
              </div>
            )}

            {sortedActivities.length === 0 && (
              <div className="notification">No timeline yet</div>
              // <div className="post-list">
              //   <div className="card">
              //     <div className="card-content">
              //       <div className="notification">No posts yet</div>
              //     </div>
              //   </div>
              // </div>
            )}

            {sortedActivities.length > 0 && (
              <table className="table is-fullwidth">
                <tbody>
                  {sortedActivities.map((activity, index) => {
                    const activityDate = activity?.activityDate;
                    return (
                      <tr key={activity?.id} className="is-bordered">
                        <td width={isMyProfile ? "55%" : "65%"}>
                          {/* className="has-text-left has-text-weight-bold is-italic" */}
                          <p
                            className="has-text-left"
                            style={{ marginBottom: 2, fontWeight: 500 }}
                          >
                            {activityDate.length > 30
                              ? format(
                                  Date.parse(activityDate),
                                  activity.format
                                    ? activity.format
                                    : "MMMM d, yyyy"
                                )
                              : `${activityDate}`}
                          </p>
                          <div>{activity?.text}</div>
                          <div
                            style={{
                              display: currentindex === index ? "flex" : "none",
                              justifyContent: "center",
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          >
                            {(activity.images !== undefined ||
                              activity.images !== null) && (
                              <div>
                                {activity?.images?.map((item, index) => {
                                  return (
                                    <ImageComponent
                                      key={index}
                                      filename={item}
                                    />
                                  );
                                })}
                              </div>
                            )}

                            {activity?.videoURL !== undefined &&
                              activity?.videoURL !== null &&
                              activity?.videoURL !== "" && (
                                <div className="youtube_video player-wrapper">
                                  <ReactPlayer
                                    className="react-player"
                                    url={activity?.videoURL}
                                    controls={true}
                                    width="100%"
                                    height={"100%"}
                                  />
                                  {/* <video src={activity?.videoURL} height={350} width={'100%'} controls></video> */}
                                </div>
                              )}

                            {activity?.videoFile !== undefined &&
                              activity?.videoFile !== null &&
                              activity?.videoFile !== "" && (
                                <div>
                                  <VideoComponent
                                    filename={activity?.videoFile}
                                  />
                                </div>
                              )}

                            {activity?.twittervideourl !== undefined &&
                              activity.twittervideourl !== null &&
                              activity?.twittervideourl !== "" && (
                                <TwitterVideoEmbed
                                  tweetId={activity?.twittervideourl}
                                />
                              )}
                          </div>
                        </td>
                        <td width={"5%"}>
                          {activity?.images ||
                          activity?.videoURL ||
                          activity?.videoFile ? (
                            <>
                              {currentindex === index ? (
                                <button
                                  className="button is-rounded margin-right is-small"
                                  onClick={() => setCurrentindex(null)}
                                >
                                  {" "}
                                  {`See Media -`}
                                </button>
                              ) : (
                                <button
                                  className="button is-rounded margin-right is-small"
                                  onClick={() => setCurrentindex(index)}
                                >
                                  {" "}
                                  {`See Media +`}
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </td>

                        {/* <td width={"10%"}>
                          <p className="has-text-right">
                            {activityDate.length > 30
                              ? format(
                                  Date.parse(activityDate),
                                  activity.format
                                    ? activity.format
                                    : "MMMM d, yyyy"
                                )
                              : `${activityDate}`}
                          </p>
                        </td> */}

                        {isMyProfile && (
                          <td width={"15%"} className="has-text-right">
                            <div>
                              <>
                                <button
                                  className="button is-rounded margin-right is-small"
                                  onClick={() =>
                                    setIsEditActivityShown({ activity })
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  className="button is-rounded is-small mx"
                                  onClick={() =>
                                    setIsDeleteActivityShown({ activity })
                                  }
                                >
                                  Delete
                                </button>
                              </>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {isEditProfileShown && (
        <EditProfileModal onClose={() => setIsEditProfileShown(false)} />
      )}
      {isEditActivityShown && (
        <EditActivityModal
          activity={isEditActivityShown.activity}
          onClose={() => setIsEditActivityShown(false)}
        />
      )}
      {isDeleteActivityShown && (
        <DeleteActivityModal
          activity={isDeleteActivityShown.activity}
          onClose={() => setIsDeleteActivityShown(false)}
        />
      )}

      {isFollowerShown && (
        <FollowerModal
          setIsFollowerShown={setIsFollowerShown}
          profiles={profile}
          isMyProfile={isMyProfile}
          selectedProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
          onClose={() => setIsFollowerShown(false)}
        />
      )}
      {isFollowingShown && (
        <FollowingModal
          profile={profile}
          isMyProfile={isMyProfile}
          setSelectedProfile={setSelectedProfile}
          selectedProfile={selectedProfile}
          onClose={() => setIsFollowingShown(false)}
          setIsFollowingShown={setIsFollowingShown}
        />
      )}
      {isSearchShown && (
        <SearchQueries
          isMyProfile={isMyProfile}
          loginProfile={profile}
          searchProfile={selectedProfile}
          setSelectedProfile={setSelectedProfile}
        />
      )}
    </div>
  );
}
export default ProfileBody;
