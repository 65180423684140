import { database, stitchClient } from "./stitch";
import { AnonymousCredential } from "mongodb-stitch-browser-sdk";

export const randomId = () => Math.random().toString(36).substr(2);

export const withTempLogin = async (func) => {
  let tempLogin = false;
  if (!stitchClient.auth.isLoggedIn) {
    await stitchClient.auth.loginWithCredential(new AnonymousCredential());
  }
  const res = await func();
  if (tempLogin) {
    await stitchClient.auth.logout();
  }
  return res;
};

// Follow And Unfollow Funtion
const usersCollection = database.collection("profiles");

export const followUser = async (userIdToFollow, profile) => {
  try {
    await usersCollection.updateOne(
      { _id: userIdToFollow._id },
      {
        $push: {
          followers: {
            _id: profile._id,
            name: profile.firstName,
            userName: profile.userName,
            image: profile.image,
          },
        },
      }
    );

    await usersCollection.updateOne(
      { _id: profile._id },
      {
        $push: {
          following: {
            _id: userIdToFollow._id,
            name: userIdToFollow.firstName,
            userName: userIdToFollow.userName,
            image: userIdToFollow.image,
          },
        },
      }
    );

    // if (updatedFollowers && updatedFollowing) {
    //   console.log(`You are now following user ${userIdToFollow}`);
    // } else {
    //   console.error("Failed to follow user.");
    // }
  } catch (error) {
    console.error("Error following user:", error);
  }
};

export const unfollowUser = async (userIdToUnfollow, profile) => {
  try {
    //const removedFollower =
    await usersCollection.updateOne(
      { _id: userIdToUnfollow._id },
      {
        $pull: {
          followers: { _id: profile._id },
        },
      }
    );
    //const removedFollowing =
    await usersCollection.updateOne(
      { _id: profile._id },
      {
        $pull: {
          following: { _id: userIdToUnfollow._id },
        },
      }
    );
    // if (removedFollower && removedFollowing) {
    //   console.log(`You have unfollowed user ${userIdToUnfollow}`);
    // } else {
    //   console.error("Failed to unfollow user.");
    // }
  } catch (error) {
    console.error("Error unfollowing user:", error);
  }
};
