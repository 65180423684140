import React from "react";

import Header from "../components/Header";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "react-multi-carousel/lib/styles.css";

function Home(props) {
  return (
    <>
      <Header />
      <div className="page-body">
        <Banner {...props} />
      </div>
      <Footer />
    </>
  );
}

export default Home;
