import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-dropdown";

import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";

import { Store } from "../Store";
import { database } from "../stitch";
import { randomId } from "../utils";

import firebaseApp from "../firebaseconfig";

import { getStorage, ref, uploadBytes } from "firebase/storage";

import VideoComponent from "./VideoComponent";
import ImageComponent from "./ImageComponent";

const options = [
  { value: "MMMM d, yyyy", label: "Month, Day and Year" },
  { value: "d", label: "Only Day" },
  { value: "yyyy", label: "Only Year" },
  { value: "MMMM", label: "Only Month" },
  { value: "MMMM, yyyy", label: "Month and Year" },
  { value: "MMMM d", label: "Month and Day" },
  { value: "d, yyyy", label: "Day and Year" },
];
const defaultOption = options[0].value;
function AddActivityModal({ onClose, activity }) {
  const [text, setText] = useState((activity && activity.text) || "");
  const [activityDate, setActivityDate] = useState(
    (activity && new Date(activity.activityDate)) || new Date()
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [format, setDateFormat] = useState(defaultOption);
  const { profile, setStore } = useContext(Store);
  const [imageList, setImageList] = useState(
    (activity && activity.images) || []
  );
  //const [setIsvideoInput] =useState((activity && activity.videoURL)!==''? true : false );
  const [embebURL, setEmbebURL] = useState(
    (activity && activity.videoURL) || ""
  );

  const [video_file, setVideo_file] = useState(
    (activity && activity.videoFile) || ""
  );
  const [videofile_cls, setVideofile_cls] = useState("");
  const [savebtndisable, setSavebtndisable] = useState(null);
  const [imageFileList, setImageFileList] = useState(
    (activity && activity.images) || []
  );
  const [videoFileList, setVideoFileList] = useState(
    (activity && activity.videoFile) || ""
  );

  const [upvideobtnclk, setUpvideobtnclk] = useState(0);
  const [upimagebtnclk, setUpimagebtnclk] = useState(0);

  const [uploadbtn, setUploadbtn] = useState(
    activity && activity.videoURL
      ? 1
      : activity && activity.videoFile
      ? 2
      : activity && activity.twittervideourl
      ? 7
      : activity && activity.images
      ? 3
      : 1
  );

  const [twitterVideoURL, setTwitterVideoURL] = useState(
    (activity && activity.twittervideourl) || ""
  );

  const imageUploadRef = React.createRef();
  const videoUploadRef = React.createRef();

  const maxSize = 2000000; // 2000000 bytes  is 2MB;
  const videomaxsize = 300000000; //300000000 bytes is 300MB;

  const onlyacceptImage = ["image/png", "image/jpeg"];
  const videoaccepted = ["video/mp4"];

  const UploadImageonFirebase = (files) => {
    const storage = getStorage(firebaseApp, "gs://ibrieft-2c6f5.appspot.com"); //getStorage();
    // Points to the root reference
    const storageRef = ref(storage);
    // Points to 'images'
    const imagesRef = ref(storageRef, "images");
    // Points to 'images/space.jpg'
    // Note that you can use variables to create child values
    const fileName = files.name;
    const spaceRef = ref(imagesRef, fileName);
    return uploadBytes(spaceRef, files).then(
      (snapshot) => snapshot.ref.fullPath
    );
  };

  const UploadVideoonFirebase = (files) => {
    const storage = getStorage(firebaseApp, "gs://ibrieft-2c6f5.appspot.com"); //getStorage();
    // Points to the root reference
    const storageRef = ref(storage);
    // Points to 'images'
    const imagesRef = ref(storageRef, "videos");
    // Points to 'images/space.jpg'
    // Note that you can use variables to create child values
    const fileName = files.name;
    const spaceRef = ref(imagesRef, fileName);
    return uploadBytes(spaceRef, files).then(
      (snapshot) => snapshot.ref.fullPath
    );
  };

  const getImageFilePathObj = async () => {
    const imagePathObj = [];
    for (const index in imageFileList) {
      const filepath = await UploadImageonFirebase(imageFileList[index]);
      //console.log("filepath: ", filepath);
      imagePathObj.push(filepath);
    }
    //console.log("imagePathObj: ", imagePathObj)
    return imagePathObj;
  };

  const addActivity = async (e) => {
    e.preventDefault();
    if (!validateText(text)) {
      setError("Text cannot be empty");
      return;
    }
    if (!validateText(activityDate)) {
      setError("Date cannot be empty");
      return;
    }
    // if (isvideoinput){
    //   if (!validateText(embebURL)){
    //     setError("select Video File.");
    //     return;
    //   }
    // }

    // if (uploadbtn === 1) {
    //   if (embebURL === "") {
    //     setError("Video URL cannot be empty.");
    //     return;
    //   }
    // }
    // if (uploadbtn === 2) {
    //   if (videoFileList.length === 0) {
    //     setError("Please select Video file.");
    //     return;
    //   }
    // }
    // if (uploadbtn === 3) {
    //   if (imageList.length === 0) {
    //     setError("select atleat one Image.");
    //     return;
    //   }
    // }

    setLoading(true);
    const imageObj =
      imageFileList.length > 0 ? await getImageFilePathObj() : [];
    //console.log("imageObxj: ", imageObj);

    const videoObj =
      videoFileList !== "" ? await UploadVideoonFirebase(videoFileList) : "";
    //console.log("videoObj: ", videoObj);

    const newActivity = {
      activityDate: activityDate.toString(),
      text,
      format: format,
      images: imageObj,
      videoURL: embebURL,
      videoFile: videoObj,
      twittervideourl: twitterVideoURL,
    };

    if (typeof activity === "object") {
      const index = profile.activities.findIndex((a) => a.id === activity.id);
      newActivity.id = activity.id;
      await database
        .collection("profiles")
        .updateOne(
          { _id: profile._id, "activities.id": activity.id },
          { $set: { "activities.$": newActivity } }
        );
      profile.activities[index] = newActivity;
    } else {
      newActivity.id = randomId();
      await database
        .collection("profiles")
        .updateOne(
          { _id: profile._id },
          { $push: { activities: newActivity } }
        );
      if (!profile.activities) profile.activities = [];
      profile.activities.push(newActivity);
    }
    setLoading(false);
    setStore("profile", profile);
    onClose();
  };

  const validateText = (value) => {
    if (value) return true;
    return false;
  };

  const fileDataURL = (file) =>
    new Promise((resolve, reject) => {
      let fr = new FileReader();
      fr.onload = () => resolve(fr.result);
      fr.onerror = reject;
      fr.readAsDataURL(file);
    });
  const onImageChange = (event) => {
    setImageList([]);
    setImageFileList([]);

    let profilePic = document.getElementById("profilePic").files;
    //console.log("profilePic: ", profilePic)
    if (profilePic.length > 4) {
      alert("You can only upload a maximum of 4 files.");
      return false;
    } else {
      Array.from(profilePic).forEach((file) => {
        //console.log("Do something with " + file.name)
        if (onlyacceptImage.indexOf(file.type) === -1) {
          //console.log("if Condition run.");
          alert("Only png and jpeg files are allowed.");
          event.target.value = null;
          return false;
        }

        if (file.size > maxSize) {
          //console.log("else if Condition run.");
          alert(`file ${file.name} size is more than 2MB.`);
          event.target.value = null;
          return false;
        }
        setImageFileList((imageFileList) => [...imageFileList, file]);

        fileDataURL(file).then((data) => {
          //console.log("File Data: ",data)

          setImageList((imageList) => [...imageList, data]);
          setUpimagebtnclk(0);
        });
      });
    }
  };

  const onVideoChange = (event) => {
    //("start Date: ", new Date())
    setVideofile_cls("viedoprocress_bar");
    setSavebtndisable(true);
    setVideo_file("");
    let videofile = document.getElementById("videofile").files[0];
    ///console.log("videofile: ", videofile.type);
    if (videoaccepted.indexOf(videofile.type) === -1) {
      //console.log("if Condition run.");
      alert("Only mp4 file are allowed.");
      setVideofile_cls("");
      event.target.value = null;
      return false;
    }

    if (videofile.size > videomaxsize) {
      alert(`file ${videofile.name} size is more than 300MB.`);
      setVideofile_cls("");
      event.target.value = null;
      return false;
    }
    setVideoFileList(videofile);
    fileDataURL(videofile).then((data) => {
      //console.log("File Data: ",data)
      setVideo_file(data);
      //console.log("end Date: ", new Date());
      setVideofile_cls("");
      setSavebtndisable(false);
      setUpvideobtnclk(0);
    });
  };

  const TwitterEmbebURLButtonClick = () => {
    setVideo_file("");
    setVideofile_cls("");
    setVideoFileList("");
    setImageList([]);
    setUploadbtn(7);
  };

  const embebURLButtonClick = () => {
    setVideo_file("");
    setVideofile_cls("");
    setVideoFileList("");
    setImageList([]);
    setTwitterVideoURL("");
    setUploadbtn(1);
  };

  const ChooseVideoButtonClick = () => {
    videoUploadRef.current.click();
    setImageList([]);
    setUploadbtn(2);
    setEmbebURL("");
    setTwitterVideoURL("");
    setUpvideobtnclk(1);
  };
  const uploadButtonClick = () => {
    imageUploadRef.current.click();
    setVideo_file("");
    setVideofile_cls("");
    setUploadbtn(3);
    setEmbebURL("");
    setVideoFileList("");
    setUpimagebtnclk(1);
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <form onSubmit={addActivity}>
        <fieldset disabled={loading}>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">
                {typeof activity === "object" ? "Edit" : "Post"}
                {/* Timeline */} Timeline
              </p>
              <button
                className="delete"
                aria-label="close"
                onClick={onClose}
              ></button>
            </header>
            <section className="modal-card-body">
              {error && <div className="notification is-danger">{error}</div>}

              <div className="field">
                <label className="label">Date</label>
                <Dropdown
                  options={options}
                  onChange={(e) => setDateFormat(e.value)}
                  value={defaultOption}
                  placeholder="Select an option"
                />

                <div className="control">
                  <DatePicker
                    required
                    className="input date-input"
                    selected={activityDate}
                    onChange={(value) => setActivityDate(value)}
                    showDateSelect
                    dateFormat={format}
                    showYearPicker={format === "yyyy" ? true : false}
                    showMonthYearPicker={
                      format === "MMMM, yyyy" || format === "MMMM"
                        ? true
                        : false
                    }
                  ></DatePicker>
                </div>
              </div>
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    required
                    className="text-timeline input"
                    type="text"
                    value={text}
                    cols="50"
                    rows="3"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">{"Add Video & Image"}</label>
                <input
                  className="is-hidden"
                  ref={imageUploadRef}
                  type="file"
                  id="profilePic"
                  onChange={onImageChange}
                  accept="image/png, image/jpeg"
                  multiple
                />
                <input
                  className="is-hidden"
                  ref={videoUploadRef}
                  type="file"
                  id="videofile"
                  onChange={onVideoChange}
                  accept="video/mp4"
                />
                <button
                  type="button"
                  className="button is-rounded is-small margin-bottom is-info mx"
                  onClick={embebURLButtonClick}
                >
                  Embed Video URL{" "}
                </button>
                <span>/</span>
                <button
                  type="button"
                  className="button is-rounded is-small margin-bottom is-info mx"
                  onClick={TwitterEmbebURLButtonClick}
                >
                  Embed Twitter Video ID{" "}
                </button>
                <span>/</span>

                <button
                  type="button"
                  className="button is-rounded is-small margin-bottom is-info mx"
                  onClick={ChooseVideoButtonClick}
                >
                  {" "}
                  Choose Video
                </button>
                <button
                  type="button"
                  className="button is-rounded is-small margin-bottom is-info"
                  onClick={uploadButtonClick}
                >
                  Upload Image
                </button>
              </div>

              {parseInt(uploadbtn) === 1 || parseInt(uploadbtn) === 7 ? (
                <div>
                  <label className="label">{`${
                    parseInt(uploadbtn) === 7 ? "Twitter " : ""
                  }Video URL ${parseInt(uploadbtn) === 7 ? "ID" : ""}`}</label>
                  <input
                    type="text"
                    className="text-embedURL input"
                    value={
                      parseInt(uploadbtn) === 7 ? twitterVideoURL : embebURL
                    }
                    onChange={(e) =>
                      parseInt(uploadbtn) === 7
                        ? setTwitterVideoURL(e.target.value)
                        : setEmbebURL(e.target.value)
                    }
                  />
                </div>
              ) : parseInt(uploadbtn) === 2 ? (
                <div className={videofile_cls}>
                  {/* <label className="label">{'Video URL'}</label>
                  <input type="text" className='text-embedURL input' value={embebURL} onChange={(e) => setEmbebURL(e.target.value) } /> */}
                  {video_file !== "" && parseInt(upvideobtnclk) === 0 ? (
                    <VideoComponent filename={video_file} />
                  ) : (
                    <video
                      src={video_file}
                      height={350}
                      width={"100%"}
                      controls
                    ></video>
                  )}
                </div>
              ) : (
                <div className="row">
                  {imageList.map((value, index) => {
                    return parseInt(upimagebtnclk) === 0 ? (
                      <ImageComponent filename={value} />
                    ) : (
                      <img
                        className="is-150x150"
                        alt={""}
                        src={value}
                        height={150}
                        width={150}
                      />
                    );
                  })}
                </div>
              )}
            </section>
            <footer className="modal-card-foot">
              <button
                type="submit"
                className={`button is-rounded is-info ${
                  loading ? "is-loading" : ""
                }`}
                disabled={savebtndisable}
              >
                Save
              </button>
              <button className="button is-rounded" onClick={onClose}>
                Cancel
              </button>
            </footer>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default AddActivityModal;
