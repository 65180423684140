import React from 'react';

function Loading() {
  return (
    <section className="section">
      <div className="container has-text-centered">
        <button className="button is-large is-loading loading-icon" />
      </div>
    </section>
  );
}

export default Loading;