import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import { userPassClient } from '../stitch';

function SetupNewPasswordBody() {
  const { history, location: { search } } = useReactRouter();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      const queryString = new URLSearchParams(search);
      const token = queryString.get('token');
      const tokenId = queryString.get('tokenId');
      if (!token || !tokenId) return setError('Invalid Url');
      setLoading(true);
      setError(null);
      await userPassClient.resetPassword(token, tokenId, password);
      setLoading(false);
      history.push('/signin', { passwordReset: true });
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <section className="section">
      <div className="container compact">
        <h1 className="title">Setup New Password</h1>
        {error && <div className="notification is-danger">{error}</div>}
        <form onSubmit={resetPassword}>
          <fieldset disabled={loading}>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input required className="input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className={`button is-rounded is-info ${loading ? 'is-loading' : ''}`}>Set Password</button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default SetupNewPasswordBody;