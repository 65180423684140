import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ConfirmEmailBody from '../components/ConfirmEmailBody';

function ConfirmEmail() {
  return (
    <>
      <Header />
      <div className="page-body"><ConfirmEmailBody /></div>
      <Footer />
    </>
  );
}

export default ConfirmEmail;