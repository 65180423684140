// eslint-disable-next-line

import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import CountrySelect from "react-country-select";
//import css module
import "react-flags-select/css/react-flags-select.css";

import { userPassClient, database } from "../stitch";
import { withTempLogin } from "../utils";
import Utilities from "../common";
// import { debounce } from "lodash";

function SignUpBody() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [occupation, setOccupation] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState("");
  const [isvaildUname, setIsvaildUname] = useState(false);
  const [isvaildEamil, setIsvaildEamil] = useState(true);
  // eslint-disable-next-line
  const [currentVal, setCurrentVal] = useState(
    //new Date().getUTCMilliseconds().toString()
    Math.floor(Math.random() * Date.now())
      .toString()
      .substring(2, 5)
  );

  // console.log(
  //   "bhnss >>>",
  //   Math.floor(Math.random() * Date.now())
  //     .toString()
  //     .substring(2, 5)
  // );

  const { history } = useReactRouter();

  const validateEmail = (mail) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const signup = async (e) => {
    e.preventDefault();
    setError(null);
    if (!validateText(email)) {
      setError("Email cannot be empty");
      return;
    }
    if (!validateEmail(email)) {
      setError("Email is invalid");
      return;
    }
    if (!validateText(firstName)) {
      setError("First Name cannot be empty");
      return;
    }
    if (!validateText(lastName)) {
      setError("Last Name cannot be empty");
      return;
    }
    if (!validateText(password)) {
      setError("Password cannot be empty");
      return;
    }
    if (userName === "") {
      setError(`User Name cannot be empty.`);
      return;
    }
    if (isvaildUname && isvaildUname.length > 0) {
      setError(`This ${userName}. not Available! Please try other one.`);
      return;
    }

    try {
      if (isvaildEamil) {
        setLoading(true);

        await userPassClient.registerWithEmail(email, password);
        const newAgent = {
          email: email.toLowerCase(),
          firstName,
          lastName,
          occupation,
          phone,
          country,
          userName,
        };
        await withTempLogin(() => {
          return database.collection("profiles").insertOne(newAgent);
        });
        setLoading(false);
        history.push("/signin", { signup: true });
      } else {
        setError("Email already exist.");
        return;
      }
    } catch (err) {
      //console.log(err);
      if (err.errorCode === 46) {
        setError("Email already exist.");
      } else {
        setError(err.message);
      }

      setLoading(false);
    }
  };

  const validateText = (value) => {
    if (value && value.length) return true;
    return false;
  };

  const setEmailId = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckEmailId = () => {
    if (email !== "") {
      withTempLogin(() => {
        return database
          .collection("profiles")
          .aggregate([{ $match: { email: email.toLowerCase() } }])
          .toArray();
      }).then((res) => {
        //console.log("res >>>>", res);
        if (res.length > 0) {
          setIsvaildEamil(false);
          setError("Email already exist.");
          return;
        } else {
          setIsvaildEamil(true);
        }
      });
      //console.log("Data Response",agents);
    }
  };

  const handleCheckUserName = () => {
    if (userName !== "") {
      withTempLogin(() => {
        return database
          .collection("profiles")
          .aggregate([{ $match: { userName: userName } }])
          .toArray();
      }).then((res) => {
        //console.log("res >>>>", res);
        setIsvaildUname(res);
      });
      //console.log("Data Response",agents);
    }
  };

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };

  const handleChangeUserName = (e) => {
    setIsvaildUname("");
    const str = e.target.value;
    if (str === "") {
      if (firstName !== "" && lastName !== "") {
        const username = Utilities.getUniqueUserName(
          firstName,
          lastName,
          currentVal
        );
        setUserName(username);
        handleCheckUserName();
      } else {
        setUserName("");
      }

      //setError("User Name cannot be empty.");
    } else {
      const numstr = str.substring(0, str.length - 3);
      setUserName(numstr + currentVal);
      handleCheckUserName();
    }
  };

  const userNameHandler = () => {
    //console.log("Name Has Changed>");
    const username = Utilities.getUniqueUserName(
      firstName,
      lastName,
      currentVal
    );
    ///console.log("username >>>", username);
    setUserName(username);
  };

  useEffect(() => {
    if (firstName !== "" && lastName !== "") {
      const username = Utilities.getUniqueUserName(
        firstName,
        lastName,
        currentVal
      );
      ///console.log("username >>>", username);
      setUserName(username);
    }
  }, [firstName, lastName, currentVal]);

  return (
    <section className="section">
      <div className="container compact">
        <h1 className="title">Register</h1>
        {error && <div className="notification is-danger">{error}</div>}
        <form onSubmit={signup}>
          <fieldset disabled={loading}>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  required
                  className="input"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmailId(e)}
                  onBlur={handleCheckEmailId}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  required
                  placeholder="Password"
                  className="input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {}
              </div>
            </div>

            <div className="field">
              <label className="label">First Name</label>
              <div className="control">
                <input
                  required
                  className="input"
                  placeholder="First Name"
                  type="text"
                  value={firstName}
                  onChange={handleChangeFirstName}
                  onBlur={userNameHandler}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Last Name</label>
              <div className="control">
                <input
                  required
                  className="input"
                  placeholder="Last Name"
                  type="text"
                  value={lastName}
                  onChange={handleChangeLastName}
                  onBlur={userNameHandler}
                />
              </div>
            </div>

            <div className="field">
              <label className="label">UserName</label>
              <div className="control">
                <input
                  className="input"
                  placeholder="UserName"
                  type="text"
                  value={userName}
                  onChange={handleChangeUserName}
                  onBlur={handleCheckUserName}
                />
                {userName !== "" && (
                  <>
                    {isvaildUname && (
                      <p
                        className={
                          isvaildUname && isvaildUname.length > 0
                            ? `has-text-danger`
                            : `has-text-success`
                        }
                      >
                        {isvaildUname && isvaildUname.length > 0
                          ? `This ${userName} not Available! Please try other one.`
                          : `Available!`}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="field">
              <label className="label">Country</label>
              <div className="control">
                <CountrySelect
                  multi={true}
                  flagImagePath="./assets/flags/"
                  onSelect={(val) => {
                    setCountry(val);
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Occupation</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Occupation"
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Phone</label>
              <div className="control">
                <input
                  className="input"
                  type="tel"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className={`button is-rounded is-info ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Register
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default SignUpBody;
