import React from 'react';
import Header from '../components/Header';
import ContentsPage from '../components/ContentsPage';
import Footer from '../components/Footer';

const contents = <>


  <h1><strong>BriefT</strong> Terms and Conditions</h1>

  These terms of use (these "Terms") represent a legal agreement between you and <strong>brieft</strong>.com. ("<strong>BriefT</strong> ") and govern your use of all of the following (collectively, the "Services"):
<br />
  (i) this website and any related website operated by <strong>BriefT</strong> (the "Site");
<br />
  (ii) any products, services or applications offered by <strong>BriefT</strong> in connection with the Site (the "Products"); and
<br />
  (iii) any information, data or other materials contained in or accessible by means of the Site or the Products (the "Information").
<br />
  By accessing or using any of the Services, you acknowledge that you have read, understood, and agree to be bound by and comply with these Terms.

<h1>User Conduct</h1>

  (a) In using the Services, you shall not:
<br />
  (i) violate any applicable international, federal or state laws, regulations or rules or any securities exchange requirements (collectively, "Laws").
<br />
  (ii) Make any Information available to <strong>BriefT</strong> if doing so would violate any applicable Laws, industry or professional codes or standards, contractual or fiduciary obligations, confidentiality obligations, or employer policies or other requirements to which you are bound;
<br />
  (iii) Transmit any information, data, images, or other materials that are unlawful, harmful, threatening, harassing, libelous, defamatory, vulgar, obscene or otherwise objectionable or that may invade another's right of privacy or infringe any intellectual property right, including patent, trademark, service mark, trade secret, copyright or other proprietary rights of any third party;
<br />
  (iv) Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;
<br />
  (v) Violate or attempt to violate the security of any of the Services, including: logging in to a server or account that you are not authorized to access; attempting to test, scan, probe or hack the vulnerability of the Site or any network used by the Site or to breach security, encryption or other authentication measures; or attempting to interfere with the Site by overloading, flooding, pinging, mail bombing or crashing it;
<br />
  (vi) Reverse engineer, decompile or disassemble any portion of the Services; or
<br />
  (vii) "Scrape" information from the Services, or use or attempt to use any engine, software, tool, agent or other device or mechanism (including browsers, spiders, robots, avatars or intelligent agents) to navigate or search any portion of the Site, other than the search engine and search agents available from <strong>BriefT</strong> on the Site and generally available to third party web browsers (e.g., Mozilla Firefox, Google Chrome and Microsoft Internet Explorer).
<br />
  (b) You shall: (i) provide true, accurate, current and complete information about yourself if prompted by the registration form; and (ii) maintain and update this information to keep it true, accurate, current, and complete.
<br />
  <h2>Additional Restrictions	</h2>

  (a) You may not develop or derive for commercial sale any Contact Data or other Information in any form, including an individual's contact information or lists of multiple individuals' contact information, that you may be granted access to through use of <strong>BriefT</strong> 's products and services. Except with the prior written consent of <strong>BriefT</strong>, you may not transfer or disclose any Contact Data or other Information to anyone else. You may only use the Site for commercial purposes after registering and paying any applicable fees for one or more applicable <strong>BriefT</strong> Services. You may not use the Services in a commercial service bureau environment including any provision or export of Information to third parties in any form whatsoever. You may use the Services solely for personal and/or your internal business purposes only, subject to the limitations set forth herein and in compliance with all applicable laws.
<br />
  (b) You may not modify, copy, distribute, display, publish, license, create derivative works from, transfer or sell any Contact Data or other Information, except that you may store, print and/or email personal or company summaries of Information as needed for your personal use (provided that such use is in compliance with these Terms).
<br />
  <h2>Submissions</h2>

  (a) You are solely responsible for the accuracy of the content of any submission you make to <strong>BriefT</strong>. <strong>BriefT</strong> reserves the right, but does not assume any obligation, to delete messages or other content that <strong>BriefT</strong>, in its sole discretion, deems abusive, defamatory, obscene, in violation of copyright or trademark laws, or otherwise unacceptable.
<br />
  (b) <strong>BriefT</strong> does not want to receive confidential or proprietary information from you. Any information or material submitted by you to <strong>BriefT</strong>, will be deemed NOT to be confidential. By sending <strong>BriefT</strong> any information or other material, you grant <strong>BriefT</strong> a non-exclusive, unrestricted, irrevocable, perpetual, royalty-free, worldwide, assignable, transferrable, sublicenseable, right and license, in all formats or media, whether now known or hereafter devised or discovered, to use, reproduce, display, transmit, translate, rent, sell, modify, disclose, publish, create derivative works from and distribute that material or information, and you also agree that <strong>BriefT</strong> is free to use any ideas, concepts, know-how or techniques that you send us for any purpose. You further agree to indemnify, defend and hold <strong>BriefT</strong> harmless from any liability arising due to the use or distribution of any such material or information.
<br />

  <h2>Provision of Services 	</h2>

  (a) <strong>BriefT</strong> may make improvements or changes in the Services at any time without notice.
<br />
  (b) <strong>BriefT</strong> shall not be responsible for any failure to remove, or delay in removing, harmful, inaccurate, unlawful, or otherwise objectionable content originating with or otherwise provided by third parties except to the extent provided by law.
<br />
  (c) <strong>BriefT</strong> will use reasonable commercial efforts to protect the integrity of data collected by you and stored with the Site. However, <strong>BriefT</strong> shall not be liable for any loss or damage resulting from total or partial loss of your data or from any corruption of your data. Data can get lost or become corrupt as a result of a number of causes, including hardware failures, software failures or bugs, or communications failures. <strong>BriefT</strong> recommends that you periodically back up your information and web summaries onto media not associated with <strong>BriefT</strong>, including printing a hard copy.
<br />
  <h2>DISCLAIMERS; LIMITATIONS	</h2>

  (a) YOU ASSUME ALL RESPONSIBILITY AND RISK FOR YOUR USE OF THE SERVICES. THE SERVICES ARE PROVIDED "AS IS" WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF TITLE, NONINFRINGEMENT, OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WITHOUT LIMITATION OF THE FOREGOING, <strong>BRIEFT</strong> EXPRESSLY DISCLAIMS ANY WARRANTY THAT THE SERVICES SHALL BE UNINTERRUPTED OR ERROR-FREE. <strong>BRIEFT</strong> DOES NOT ASSUME ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY INFORMATION MADE AVAILABLE THROUGH THE SERVICES.
<br />
  (b) IN NO EVENT SHALL <strong>BRIEFT</strong> BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OF, DELAY IN USING, OR INABILITY TO USE THE SERVICES. <strong>BRIEFT</strong> 'S LIABILITY FOR ANY DIRECT DAMAGES SHALL BE LIMITED TO THE AMOUNT OF FEES YOU HAVE PAID FOR THE SERVICES FOR THE THEN-CURRENT PERIOD. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES AND THUS THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF THIS LIMITATION OF LIABILITY OR THE EXCLUSION OF WARRANTY SET FORTH ABOVE IS HELD INAPPLICABLE OR UNENFORCEABLE FOR ANY REASON, <strong>BRIEFT</strong>'S MAXIMUM LIABILITY FOR ANY TYPE OF DAMAGES SHALL BE LIMITED TO $50 Australian Dollar.
<br />


  <h2>Privacy</h2>

  our use of the Services is subject to <strong>BriefT</strong>'s Privacy Policy, the provisions of which are incorporated herein by this reference. By using the Services, you acknowledge and consent to <strong>BriefT</strong>'s Privacy Policy, including <strong>BriefT</strong>'s collection, use and disclosure of information.



<h2>Changes to the Terms	</h2>

  <strong>BriefT</strong> may modify these Terms at any time by posting changes on the Site; however, (i) such changes will only become effective and binding after <strong>BriefT</strong> provides notice on the Site or by email that these Terms have changed, and you first use the Services following the date of such posting, (ii) the changes will only apply with respect to your use of the Services after such changes become effective, and (iii) any imposition or change of fee obligations will only apply if and when you expressly agree. If at any time you find these Terms unacceptable and do not agree with them, you thereafter will have no right to use or access the Services.




</>;

function Terms() {
  return (<>
    <Header />
    <div className="page-body"><ContentsPage title="Terms and Conditions" contents={contents} /></div>
    <Footer />
  </>)
}

export default Terms;