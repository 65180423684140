import React from "react";
import { Link } from "react-router-dom";
// import { SocialIcon } from 'react-social-icons';

import PATHS from "../routePaths";

function Footer() {
  return (
    <footer className="section">
      <div className="container">
        <hr className="hr" />
        <div className="footer-custom custom_footer">
          <div className="column is-4 custom_footer_left">
            <div className="columns is-gapless mb-1 childfooter is-mobile">
              <div className="column is-4 is-three-quarters-mobile">
                <Link to={PATHS.about}>Abouts briefT</Link>
              </div>
              <div className="column is-4">
                <Link to={PATHS.privacy}>Privacy Policy</Link>
              </div>
              <div className="column is-4">
                <Link to={PATHS.terms}>Terms and Condition</Link>
              </div>
            </div>
          </div>
          <div
            style={{ alignSelf: "center", fontSize: "12px" }}
            className="custom_footer_right"
          >
            &copy; {`${new Date().getFullYear()}  briefT. All rights reserved.`}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
