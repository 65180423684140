import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Contact() {

  return (<>
    <Header />
    <div className="page-body">
      <section className="section">
        <div className="container">
          <h2 className="title">Contact Us</h2>
          <div className="contents has-text-justified">
            <p>For general enquires please contact us at info@brieft.com</p>
            <br />
            <p>For business and advertising inquiries contact us at business@brieft.com</p>
            <br />
            <p>For profile related matters please contact us at admin@brieft.com</p>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>)
}

export default Contact;