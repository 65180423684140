import React, { useEffect, useContext } from 'react';
import useReactRouter from 'use-react-router';

import { Store } from '../Store';
import { stitchClient } from '../stitch';

function Signout() {
  const { history } = useReactRouter();
  const { setStore } = useContext(Store);

  useEffect(() => {
    const logout = async () => {
      setStore('loading', true);
      await stitchClient.auth.logout();
      setStore('profile', null);
      setStore('loading', false);
      history.push('/');
    }
    logout();
  });

  return (<></>);
}

export default Signout;