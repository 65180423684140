import React, { useState, useEffect } from "react";
import DummyImage from "./DummyImage.json";
import { withTempLogin } from "../utils";
import { database } from "../stitch";
import { ObjectId } from "bson";

function MsgHeader({ id }) {
  //console.log("dataobj >>", dataobj)
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    let isMounted = true; // Add this variable to track the component's mounted state.

    const fetchProfile = async () => {
      try {
        if (id) {
          const record = await withTempLogin(() => {
            return database
              .collection("profiles")
              .findOne({ _id: new ObjectId(id) });
          });
          if (isMounted) {
            setSelectedProfile(record);
          }
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();

    return () => {
      // This cleanup function will be executed when the component is unmounted.
      isMounted = false; // Mark the component as unmounted to prevent state updates.
    };
  }, [id]);

  const image = `data:image/png;base64, ${
    selectedProfile?.image || DummyImage?.data
  }`;

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: 0,
      }}
    >
      <div>
        <img
          className="postMessagsImg"
          src={image}
          alt={selectedProfile ? selectedProfile?.firstName : "userImg"}
        />
      </div>
      <div>
        <div className="is-6" style={{ fontWeight: 600 }}>
          {selectedProfile?.firstName + " " + selectedProfile?.lastName}
          {selectedProfile?.showflag && selectedProfile?.country ? (
            selectedProfile?.country.map((value, index) => {
              return (
                <img
                  key={index}
                  className="image-flag-msg"
                  src={require(`../../public/assets/flags/${value?.value}.png`)}
                  alt="country"
                />
              );
            })
          ) : (
            <div></div>
          )}
        </div>
        <div style={{ opacity: "0.6" }}>{`@${selectedProfile?.userName}`}</div>
      </div>
    </div>
  );
}

export default MsgHeader;
