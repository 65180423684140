import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

import Loading from "./Loading";
import { database } from "../stitch";
import { withTempLogin } from "../utils";
// import { ObjectId } from "bson";
import { Store } from "../Store";
import GolobalFollowerButton from "./GolobalFollowerButton";
function SearchQueries({
  query,
  setSelectedProfile,
  selectedProfile,
  isMyProfile,
}) {
  const { profile } = useContext(Store);
  const searchQuery = query.toLowerCase();
  const [loading, setLoading] = useState(true);
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(0);
  const [records, totalRecords] = useState(0);
  const didMountRef = useRef(true);

  const getResult = useCallback(async () => {
    const agents = await withTempLogin(() => {
      return database
        .collection("profiles")
        .aggregate([
          {
            $project: {
              fullName1: { $concat: ["$firstName", " ", "$lastName"] },
              fullName2: { $concat: ["$firstName", "$lastName"] },
              firstName: 1,
              lastName: 1,
              occupation: 1,
              userName: 1,
            },
          },
          {
            $match: {
              $or: [
                {
                  fullName1: { $regex: new RegExp(searchQuery, "i") },
                },
                {
                  fullName2: { $regex: new RegExp(searchQuery, "i") },
                },
                {
                  userName: { $regex: new RegExp(searchQuery, "i") },
                },
              ],
            },
          },
          {
            $sort: { fullName1: 1, userName: 1 },
          },

          { $skip: page * 20 },
          { $limit: 20 },
        ])
        .toArray();
    });
    setLoading(false);
    setAgents(agents);
  }, [page, searchQuery]);
  // const isUserFollowing = (userObj) => {
  //   // console.log(
  //   //   "profile?.following?.userid",
  //   //   profile.following[0].userid.toString()
  //   // );
  //   // console.log("userObj?.followers?.userid>", userObj);
  //   // if (
  //   //   String(profile?.following?.userid) === String(userObj?.followers?.userid)
  //   // ) {
  //   // }
  // };
  useEffect(() => {
    if (didMountRef.current) {
      const getCount = async () => {
        const agents = await withTempLogin(() => {
          return database
            .collection("profiles")
            .aggregate([
              {
                $project: {
                  fullName1: { $concat: ["$firstName", " ", "$lastName"] },
                  fullName2: { $concat: ["$firstName", "$lastName"] },
                  firstName: 1,
                  lastName: 1,
                  occupation: 1,
                  userName: 1,
                },
              },
              {
                $match: {
                  $or: [
                    {
                      fullName1: { $regex: new RegExp(searchQuery, "i") },
                    },
                    {
                      fullName2: { $regex: new RegExp(searchQuery, "i") },
                    },
                    {
                      userName: { $regex: new RegExp(searchQuery, "i") },
                    },
                  ],
                },
              },
              {
                $sort: { fullName1: 1, userName: 1 },
              },

              { $count: "count" },
            ])
            .toArray();
        });
        totalRecords(agents[0]?.count);
      };

      getCount().then(() => {
        getResult();
      });
      didMountRef.current = false;
    } else {
      getResult();
    }
  }, [searchQuery, getResult]);

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="section">
      <div className="container medium">
        <h1 className="title">
          Search Results for "<span className="has-text-bold">{query}</span>"
        </h1>
        {agents.length === 0 && (
          <div className="notification">No results found</div>
        )}
        {agents.length > 0 && (
          <div className="table-container">
            <table className="table is-fullwidth is_right_viewprofilebtn">
              <tbody>
                {agents.map((agent, index) => (
                  <tr key={agent._id}>
                    <td>
                      {agent.firstName} {agent.lastName}
                    </td>
                    <td>
                      <span className="overfow-text">{agent.occupation}</span>
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/${agent.userName}`,
                          state: { id: agent._id },
                        }}
                      >
                        View Profile
                      </Link>
                    </td>
                    <td>
                      <GolobalFollowerButton
                        agent={agent}
                        profile={profile}
                        agents={agents}
                        setAgents={setAgents}
                        index={index}
                      />
                      {/* {profile === null ? (
                      <></>
                    ) : (
                      <div>
                        {profile?._id?.toString() === agent._id?.toString() ? (
                          <></>
                        ) : (
                          <div>
                            <div>
                              <GolobalFollowerButton
                                agent={agent}
                                profile={profile}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    )} */}

                      {/* <button
                      // onClick={() => {
                      //   if (following) {
                      //     unfollowUser(agent, profile).then(() => {
                      //       setFollowings(false);
                      //     });
                      //   } else {
                      //     followUser(agent, profile).then(() => {
                      //       setFollowings(true);
                      //     });
                      //   }
                      // }}

                      // onClick={() =>
                      //   updateFollowingAndFollowers(agent, profile)
                      // }

              
                      className={`${
                        isUserFollowing(agent)
                          ? "button is-dark"
                          : "button postMessagebg"
                      }`}
                    >
                      {isUserFollowing(agent) ? "Following" : "Follow"}
                    </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div
          className={`${
            agents.length > 8 ? "is-mobile aligncenter" : "paginationBottom"
          }    `}
        >
          {agents.length > 0 && (
            <div className="totalResultLabel">Total Results: {records}</div>
          )}
          {records && records > 20 && (
            <div className="pagination-block">
              <ReactPaginate
                breakLabel={"..."}
                //breakClassName={"break-me"}
                pageCount={records / 20}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                //containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                previousLabel={"Previous"}
                pageClassName="page-item"
                //pageLinkClassName="page-link"
                previousClassName="page-item"
                //previousLinkClassName="page-link"
                nextClassName="page-item"
                //nextLinkClassName="page-link"
                breakClassName="page-item"
                //breakLinkClassName="page-link"
                containerClassName="pagination"
                //activeClassName="active"
                nextLabel={"Next"}
                onPageChange={(e) => {
                  setPage(e.selected);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SearchQueries;
