import React from 'react';
import Header from '../components/Header';
import ContentsPage from '../components/ContentsPage';
import Footer from '../components/Footer';

const contents = <>
<h1>BriefT Privacy Policy</h1>
<br />
    BriefT understands that you care about how information about you is used. This privacy policy explains how we collect, share and use information pertaining to people and all other types of information through our website and online services (the "Site"); how we maintain, use and share that information; and how you can manage the way information about you is handled.
    <br />
    Our services include our websites and applications that link to This policy describes the types of information we may collect from you or that you may provide when you use our services and our practices for collecting, using, maintaining, protecting, and disclosing that information.
    <br />
    This policy applies to information we collect:
    <br />
        • When you use or interact with our services.
        <br />
        • In your email, text, and other electronic messages with us.
        <br />
        • Through our mobile and desktop applications.
    <br />
    <h2>Where does BriefT get the information for its Public Profiles?</h2>
    
    <strong>BriefT</strong> creates profiles of people, which we call "Public Profiles," from different sources. Once we have collected timely Information about a person, we combine multiple mentions of the same person in a timewise format into a Public Profile. The resulting directory of Public Profiles (the "Directory") is then made available to the users of the Site.
    <br />
    As an open website to public, people can become registered users of our website (“<strong>Members</strong>”) and share their identities, post and view other timeline related activities. Content and data on most of our Services is viewable to non-members (“<strong>Visitors</strong>”).	
    <br />
    <h1>Information We Collect</h1>
    <br />
    Creating a public profile
    <br />
    To become a member, we need you to provide information including your full name, email address and/or mobile number, and a password. 
    <br />
    Once you are a member you need to update your profile and post relevant information you wish to go on your profile including important dates and a description of the date.
    <br />
    Please remember the information you add on your profile will be publicly available, please do not add the information that is sensitive for you and you wish, it is not available on public.	
    
    <br />
    <h1>Cookies and Similar Technologies</h1>
    <br />
    When you visit our website, we may obtain certain information by automated means, such as browser cookies, Adobe Flash cookies, pixels, web beacons, device identifiers, server logs, and other technologies. A “browser cookie” is a text file that a website sends to a visitor’s device to identify the device or store settings and other information about your visit. A “web beacon” (also known as an Internet tag, pixel tag or clear GIF) is a mechanism that collects and transmits information about your device and interactions with our website and emails, such as how you use our site, access our emails and view our content.
    <br />
    We may use these automated technologies to collect information about your equipment, browsing actions, and usage patterns. This information helps us remember your information so you will not have to re-enter it, understand how you use and interact with our website and emails and tailor them to your preferences, measure the usability of our website and the effectiveness of our communication, provide you personalized advertising, track response rates to our emails, manage and enhance our communications and website, and for other purposes listed below.
    <br />
    You can manage cookies through your web browser. Most browsers will tell you how to stop accepting new cookies, how to be notified when you receive a new cookie, and how to disable existing cookies. You can find out how to do this for your particular browser by clicking “help” on your browser’s menu or by visiting www.allaboutcookies.org. Please note, however, that without cookies you may not be able to take full advantage of all our website features. Our website is not designed to respond to “do not track” signals received from browsers.
    
    <br />
    <h1>Google Analytics</h1>
    <br />
    We use Google Analytics, a web analytics service provided by Google, Inc., on our Site. Google Analytics uses cookies or other tracking technologies to help us analyse how users interact with and use the Site, compile reports on the Site's activity, and provide other services related to Site activity and usage. The technologies used by Google may collect information such as your IP address, time of visit, whether you are a return visitor, and any referring website. The Site does not use Google Analytics to gather information that personally identifies you. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google's privacy policies. To learn more about Google's partner services and to learn how to opt out of tracking of analytics by Google click here.
    <br />
    <i>Disclosures for Legal Reasons</i>
    <br />
    We may disclose collected information, including Your Personal Information, to a third party if we believe in good faith that such disclosure is necessary or desirable: (i) to comply with lawful requests, subpoenas, search warrants or orders by public authorities, including to meet national security or law enforcement requirements, (ii) to address a violation of the law, (iii) to protect the rights, property or safety of BriefT, its users or the public, or (iv) to allow BriefT to exercise its legal rights or respond to a legal claim.
    <br />
    If you provide information, including Your Personal Information, in creating or updating your Public Profile, that information will be included in the Directory and thus can be viewed by third parties.
    <br />
    
    <h2>Changes to this Policy</h2>
    <br />
    <strong>BriefT</strong> reserves the right to modify this privacy policy from time to time, so please review it regularly. If we make material changes to this policy, we will notify you here, by email, and/or by means of a notice on our homepage prior to the changes becoming effective.	
    <br />
    
    <h2>Contact Us</h2>
    <br />
    If you have questions or concerns regarding this privacy policy, please contact us at:
    <br />
    <strong>BriefT</strong> Privacy Site Manager
    <br />
    {/* Email: privacy@brieft.com */}
    Email: contact@brieft.com
    </>;

function Privacy() {
    return(<>
      <Header/> 
      <div className="page-body"><ContentsPage title="Privacy Policy" contents={contents} /></div>
      <Footer />
    </>);
}

export default Privacy;