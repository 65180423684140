import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function VideoComponent({ filename }) {
  //console.log("filename Video Component: ", filename)

  const [fileurl, setFileurl] = useState("");

  const getFileURL = async (filename) => {
    //console.log("filename: ", filename)
    //const storage = getStorage(firebaseApp, 'gs://ibrieft-2c6f5.appspot.com');//getStorage();
    // Points to the root reference
    const storage = getStorage();
    const starsRef = ref(storage, filename);
    // const storageRef = ref(storage);
    const downloadurl = await getDownloadURL(starsRef);

    setFileurl(downloadurl);
    // console.log("downloadurl: ", downloadurl);
    // return downloadurl;
  };

  useEffect(() => {
    getFileURL(filename);
    return () => {};
  }, [filename]);

  return (
    <>
      <video src={fileurl} height={"100%"} width={"100%"} controls></video>
    </>
  );
}

export default VideoComponent;
