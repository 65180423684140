import React, { useState } from 'react';

import { userPassClient } from '../stitch';

function ResetPasswordBody() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);

  const resetPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      await userPassClient.sendResetPasswordEmail(email);
      setLoading(false);
      setEmailSent(true);
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  if (emailSent) {
    return (
      <section className="section">
        <div className="container compact">
          <div className="notification is-info">Password reset link has been sent to email address</div>
        </div>
      </section>
    );
  }

  return (
    <section className="section">
      <div className="container compact">
        <h1 className="title">Reset Password</h1>
        {error && <div className="notification is-danger">{error}</div>}
        <form onSubmit={resetPassword}>
          <fieldset disabled={loading}>
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input required className="input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className={`button is-rounded is-info ${loading ? 'is-loading' : ''}`}>Send Reset Link</button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default ResetPasswordBody;