import React from 'react';

function LoadingPage() {
  return (
    <div className="page-body">
      <section className="hero is-fullheight-with-navbar">
        <div className="hero-body">
          <div className="container has-text-centered">
            <button className="button is-large is-loading loading-icon" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoadingPage;