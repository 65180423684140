import { initializeApp } from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyAe0DQSCU037C697gXi47Wbk4nWDnmzNdU",
  authDomain: "ibrieft-2c6f5.firebaseapp.com",
  projectId: "ibrieft-2c6f5",
  storageBucket: "ibrieft-2c6f5.appspot.com",
  messagingSenderId: "924922258657",
  appId: "1:924922258657:web:4818051de240ea367283a0",
  measurementId: "G-WYNRG40JWJ"
    
};
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the storage service, which is used to create references in your storage bucket
export default firebaseApp;