import {
  Stitch,
  UserPasswordAuthProviderClient,
  RemoteMongoClient,
} from "mongodb-stitch-browser-sdk";

const APP_ID = "brieft-bxvde";

export const stitchClient = Stitch.initializeDefaultAppClient(APP_ID);

export const userPassClient = Stitch.defaultAppClient.auth.getProviderClient(
  UserPasswordAuthProviderClient.factory
);

const mongodb = stitchClient.getServiceClient(
  RemoteMongoClient.factory,
  "mongodb-atlas"
);

export const database = mongodb.db("brieft");
