import React from "react";
import { Link } from "react-router-dom";

export function TryFollow(props) {
  const {
    enableTryModal,
    userName = "",
    setEnableTryModal,
    setTryUserName,
  } = props;

  //console.log("tryUserName >>>", userName);
  return (
    <div
      className={`modal ${enableTryModal ? "is-active" : ""}`}
      id={"modal-ter"}
    >
      <div className="modal-background"></div>
      <div className="modal-card custom-modal-card">
        <header
          className="modal-card-head content-modal-card-head"
          style={{ justifyContent: "right" }}
        >
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setEnableTryModal(false);
              setTryUserName("");
            }}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="content-modal">
            {/* <div className="sizefont">
                <i className="fas fa-exclamation-circle"></i>
              </div> */}
            <h4>
              Follow
              <span style={{ fontWeight: "bold" }}>{` "${userName}"`}</span> to
              see what they share on
              <span style={{ fontWeight: "bold" }}>{` BriefT.`}</span>
            </h4>
            <p>
              <Link to={`/signin`}>
                <span className="signupin">{`Sign in`}</span>
              </Link>
              {"/"}
              <Link to={`/signup`}>
                <span className="signupin">{`Sign up `}</span>
              </Link>
              {`so you never miss their posts.`}
            </p>
            <div className="butons-modal">
              <button
                className="button"
                onClick={() => {
                  setEnableTryModal(false);
                  setTryUserName("");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
