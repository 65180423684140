import React, { useState, useEffect } from 'react';
import useReactRouter from 'use-react-router';

import { userPassClient } from '../stitch';

function ConfirmEmailBody() {
  const { history, location: { search } } = useReactRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const confirm = async () => {
      try {
        const queryString = new URLSearchParams(search);
        const token = queryString.get('token');
        const tokenId = queryString.get('tokenId');
        if (!token || !tokenId) return setError('Invalid Url');
        setLoading(true);
        await userPassClient.confirmUser(token, tokenId);
        setLoading(false);
        history.push('/signin', { verified: true });
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    };
    confirm();
  }, [history, search]);

  return (
    <section className="section">
      <div className="container compact">
        <h1 className="title">Email Verification</h1>
        {loading && <button className="button is-large is-loading loading-icon" />}
        {error && <div className="notification is-danger">{error}</div>}
      </div>
    </section>
  );
}

export default ConfirmEmailBody;