import React from "react";
import { TwitterTweetEmbed } from "react-twitter-embed";

const TwitterVideoEmbed = ({ tweetId }) => {
  return (
    <div>
      <TwitterTweetEmbed
        tweetId={tweetId} // replace with the actual Tweet ID
        options={{ conversation: "none" }}
      />
    </div>
  );
};

export default TwitterVideoEmbed;
