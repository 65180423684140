import React, { useState, useContext } from "react";

import { Store } from "../Store";
import { database } from "../stitch";

function DeleteActivityModal({ onClose, activity }) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { profile, setStore } = useContext(Store);

  const deleteActivity = async () => {
    try {
      setLoading(true);
      const index = profile.activities.findIndex((a) => a.id === activity.id);
      await database
        .collection("profiles")
        .updateOne(
          { _id: profile._id },
          { $pull: { activities: { id: activity.id } } }
        );
      profile.activities.splice(index, 1);
      setStore("profile", profile);
      setLoading(false);
      onClose();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Delete Timeline?</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          {error && <div className="notification is-danger">{error}</div>}
          <p>Do you really want to delete this timeline?</p>
        </section>
        <footer className="modal-card-foot">
          <button
            type="button"
            onClick={deleteActivity}
            className={`button is-rounded is-danger ${
              loading ? "is-loading" : ""
            }`}
            disabled={loading}
          >
            Yes
          </button>
          <button
            className="button is-rounded"
            disabled={loading}
            onClick={onClose}
          >
            No
          </button>
        </footer>
      </div>
    </div>
  );
}

export default DeleteActivityModal;
