import React, { useContext, useState } from "react";
import formatDate from "date-fns/format";
import { Store } from "../Store";
//import clone from "lodash/clone";
//import {figure, img } from 'bulma';
import PostMessageModal from "./PostMessageModal";
import MsgHeader from "./MsgHeader";
import { database } from "../stitch";
import { ObjectId } from "bson";
import { randomId } from "../utils";
import DeleteMessage from "./DeleteMessage";
import { Toaster } from "react-hot-toast";
import { clone } from "lodash";

//import DeleteMessage from "./DeleteMessage";

export default function PostMessage(props) {
  const { profile, setStore } = useContext(Store);
  const [isPostMessageShown, setIsPostMessageShown] = useState(false);
  //const [showdeleteMenu, setShowdeleteMenu] = useState(false);
  //const ref = useRef();
  //console.log("props.sortedActivities._id >>", props ,props.sortedActivities._id)
  const selectedProfile = { ...props.sortedActivities };

  //console.log("selectedProfile >>>", props);

  const tmpsortedActivities =
    selectedProfile &&
    selectedProfile.timelineMsg &&
    selectedProfile.timelineMsg.length > 0
      ? clone(selectedProfile.timelineMsg).sort(
          (a, b) => new Date(b.msgDate) - new Date(a.msgDate)
        )
      : [];

  // const messageLikeCountHandler = async (index, e) => {
  //   try{
  //   console.log("Click Like")
  //   const tempObj = [...tmpsortedActivities];
  //   const checkdata =  tempObj[index]?.likes?.filter(
  //     (item) => item.userid.toString() === profile._id.toString()
  //   ).length;
  //   if (checkdata > 0){
  //     const likesObj = { id: randomId(), userid: ObjectId(profile._id) };
  //     const chkObj = tempObj[index]?.likes.length > 0 ? tempObj[index]?.likes?.filter(
  //       (item) => item.userid.toString() !== profile._id.toString()
  //     ) : [likesObj];
  //     //console.log("chkObj >>>", chkObj)
  //     tempObj[index] = {...tempObj[index], likes: chkObj}
  //     //console.log("Remove Data tempObj >>>>", tempObj[index])
  //   }
  //   else{
  //     const likesObj = [{ id: randomId(), userid: ObjectId(profile._id) }];
  //     tempObj[index] = {...tempObj[index], likes:likesObj}
  //     //console.log("Exists tempObj >>>>", tempObj[index])
  //   }

  //   //console.log("Final Object tempObj >>>>", tempObj[index])
  //   tmpsortedActivities = tempObj;
  //   profile.timelineMsg = tempObj

  //   await database
  //     .collection("profiles")
  //     .updateOne(
  //       { _id: ObjectId(profile._id) },
  //       { $set: { timelineMsg: tempObj } }
  //     );

  //   setStore('profile',profile)
  // }
  // catch(err){
  //   console.log("error likes will not updated >>", err)
  // }

  // };

  const messageLikeCountHandler = async (index, e) => {
    try {
      console.log("click like button");
      const likesObj = {
        id: randomId(),
        userid: ObjectId(profile._id),
      };
      const tempObj = [...tmpsortedActivities];

      if (
        tempObj[index]?.likes?.filter(
          (item) => item.userid.toString() === profile._id.toString()
        ).length === 0
      ) {
        tempObj[index].likes = [...tempObj[index].likes, likesObj];
      } else {
        tempObj[index].likes =
          tempObj[index]?.likes?.length > 0
            ? tempObj[index]?.likes?.filter(
                (item) => item.userid.toString() !== profile._id.toString()
              )
            : [likesObj];
      }

      const updateid = props.id ? new ObjectId(props.id) : profile._id;

      await Promise.all([
        database
          .collection("profiles")
          .updateOne({ _id: updateid }, { $set: { timelineMsg: tempObj } }),
      ]);

      if (props.id) {
        props.sortedActivities.timelineMsg = tempObj;
        props.setSelectedProfile(props.sortedActivities);
      } else {
        profile.timelineMsg = tempObj;
        setStore(profile);
      }

      // console.log("response >>>", response);
      // if (response.matchedCount === 1) {
      //   profile.timelineMsg = tempObj;
      //   //console.log("tempObj >>", tempObj);
      //   setStore(profile);
      // } else {
      //   console.log("response >>> error");
      // }
    } catch (err) {
      console.log("err >>", err);
    }
  };

  return (
    <>
      <Toaster />
      {props.isMyProfile && (
        <div
          className="is-vcentered is-mobile"
          style={{
            display: "block",
            flexBasis: "0",
            flexGrow: "1",
            flexShrink: "1",
          }}
        >
          <div
            style={{
              textAlign: "right",
              marginBottom: "16px",
              display: "block",
              flexBasis: "0",
              flexGrow: "1",
              flexShrink: "1",
            }}
          >
            <button
              className="button is-rounded postMessagebg"
              onClick={() => setIsPostMessageShown(true)}
            >
              Add Message
            </button>
          </div>
        </div>
      )}

      <div className=" is-vcentered is-mobile">
        <div
          className=""
          style={{
            display: "block",
            flexBasis: "0",
            flexGrow: "1",
            flexShrink: "1",
          }}
        >
          {tmpsortedActivities?.length === 0 && (
            <div className="post-list">
              <div className="notification">No message yet.</div>
            </div>
          )}
          {tmpsortedActivities?.length > 0 && (
            <div className="table is-fullwidth">
              <div>
                {tmpsortedActivities.map((activity, index) => {
                  return (
                    <div className="message-box-borderd" key={activity.id}>
                      <div className="msg-box-name-date">
                        <div>
                          {/* <MsgHeader dataobj={props.sortedActivities} /> */}
                          <MsgHeader
                            id={
                              activity?.id
                                ? props.sortedActivities._id
                                : activity?.senderid
                            }
                          />
                        </div>
                        <div
                          className={
                            props.isMyProfile ? "threedotsMessage_div" : ""
                          }
                        >
                          <div
                            className="timestamp"
                            style={{ marginRight: "10px" }}
                          >
                            {activity.msgDate.length > 30
                              ? formatDate(
                                  Date.parse(activity.msgDate),
                                  activity.format
                                    ? activity.format
                                    : "MMMM d, yyyy hh:mm:ss a"
                                )
                              : `${activity.msgDate}`}
                          </div>
                          {props.isMyProfile && (
                            <DeleteMessage
                              sortedActivities={tmpsortedActivities}
                              activity={activity}
                              index={index}
                            />
                          )}
                        </div>
                      </div>

                      <div style={{ marginTop: 10, marginLeft: "10px" }}>
                        {activity.text}
                      </div>

                      {(props.isMyProfile || profile) && (
                        <div className="mt-4" style={{ marginLeft: "5px" }}>
                          <button
                            className={"custom-heart"}
                            onClick={(e) => messageLikeCountHandler(index, e)}
                          >
                            <span className="mr-2 likebutton">Like</span>
                            <i
                              className="far fa-heart"
                              style={{ color: "#3fa2de" }}
                            ></i>
                          </button>
                          <span className="ml-2 font-weight-bold">
                            {`${activity?.likes ? activity?.likes?.length : 0}`}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {isPostMessageShown && (
        <PostMessageModal
          isMyProfile={props.isMyProfile}
          //activity={isDeleteActivityShown.activity}
          onClose={() => setIsPostMessageShown(false)}
        />
      )}
    </>
  );
}
