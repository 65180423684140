import React, { useState, useContext, useCallback, useEffect } from "react";
import CountrySelect from "react-country-select";

import { Store } from "../Store";
import DummyImage from "./DummyImage.json";
import { database } from "../stitch";
//import { withTempLogin } from "../utils";
import Utilities from "../common";

function EditProfileModal({ onClose }) {
  const { profile, setStore } = useContext(Store);
  const [firstName, setFirstName] = useState(profile.firstName || "");
  const [lastName, setLastName] = useState(profile.lastName || "");
  const [phone, setPhone] = useState(profile.phone || "");
  const [occupation, setOccupation] = useState(profile.occupation || "");
  const [image, setImage] = useState(profile.image || "");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(profile.country || []);
  const [userName, setUserName] = useState(profile.userName || "");
  const [isvaildUname, setIsvaildUname] = useState(false);
  //const username= profile.userName || '';
  const email = profile.email || "";
  const [showflag, setShowflag] = useState(profile.showflag || false);

  const checknber = profile.userName.match(/(\d+)/);
  // eslint-disable-next-line
  const [currentVal, setCurrentVal] = useState(
    checknber
      ? checknber[0]
      : Math.floor(Math.random() * Date.now())
          .toString()
          .substring(2, 5)
  );

  const profileId = profile && profile._id;

  //console.log("profile.country: ", country[0]);

  const imageUploadRef = React.createRef();

  const toDataURL = (file, callback) => {
    let f = file;
    let reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        callback(base64String);
      };
    })(f);
    return reader.readAsBinaryString(f);
  };

  const onImageChange = (event) => {
    let profilePic = document.getElementById("profilePic").files;

    if (profilePic && profilePic.length > 0) {
      profilePic = profilePic[0];

      toDataURL(profilePic, (image) => {
        setImage(image);
      });
    } else {
      profilePic = null;
    }
  };

  const validateText = (value) => {
    if (value && value.length) return true;
    return false;
  };

  const saveProfile = async (e) => {
    e.preventDefault();
    setError(null);
    if (!validateText(firstName)) {
      setError("First Name cannot be empty");
      return;
    }

    if (!validateText(lastName)) {
      setError("Last Name cannot be empty");
      return;
    }

    if (!validateText(userName)) {
      setError("User Name cannot be empty");
      return;
    }

    if (isvaildUname && isvaildUname.length > 0) {
      setError(`This ${userName}.Not Available! Please try other one.`);
      return;
    }

    const newProfile = {
      firstName,
      lastName,
      occupation,
      image,
      phone,
      userName,
      country,
      showflag,
    };
    setLoading(true);
    await database
      .collection("profiles")
      .updateOne({ email: profile.email }, { $set: newProfile });
    setLoading(false);
    setStore("profile", { ...profile, ...newProfile });

    closeModal();
  };

  const closeModal = () => {
    onClose();
  };

  //console.log(setCurrentVal);

  const handleCheckUserName = useCallback(async () => {
    //console.log("userName >>>", userName);
    // const agents = await withTempLogin(() => {
    // });
    const agents = await database
      .collection("profiles")
      .aggregate([{ $match: { _id: { $ne: profileId }, userName: userName } }])
      .toArray();
    //console.log("Data Response", agents);
    setIsvaildUname(agents);
  }, [userName, profileId]);

  const handleChangeUserName = (e) => {
    setIsvaildUname("");
    const str = e.target.value;
    if (str === "") {
      setUserName(profile.userName);
      //setError("User Name cannot be empty.");
    } else {
      setError(null);
      const numstr = str.substring(0, str.length - 3);
      //console.log("numstr >>>", numstr);
      setUserName(numstr + currentVal);
      handleCheckUserName();
    }
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handlerLastName = (e) => {
    setLastName(e.target.value);
  };

  // const userNameHandler = (e) => {
  //   const username = Utilities.getUniqueUserName(
  //     firstName,
  //     lastName,
  //     currentVal
  //   );
  //   //console.log("username >>>", username);
  //   setUserName(username);
  //   //}
  // };

  useEffect(() => {
    // setCurrentVal(
    //   checknber ? checknber[0] : new Date().getUTCMilliseconds().toString()
    // );
    if (profile.userName === "" || userName === "") {
      const username = Utilities.getUniqueUserName(
        firstName,
        lastName,
        currentVal
      );
      setUserName(username);
    }
  }, [firstName, lastName, userName, profile, currentVal]);

  const displayImage = `data:image/png;base64, ${image || DummyImage.data}`;

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <form className="newedit" onSubmit={saveProfile}>
          <fieldset disabled={loading}>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">Edit Profile</p>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={closeModal}
                ></button>
              </header>
              <section className="modal-card-body">
                {error && <div className="notification is-danger">{error}</div>}
                <figure className="image is-150x150 margin-bottom">
                  <img src={displayImage} alt={profile.firstName} />
                </figure>
                <input
                  className="is-hidden"
                  ref={imageUploadRef}
                  type="file"
                  id="profilePic"
                  onChange={onImageChange}
                />
                <button
                  type="button"
                  className="button is-rounded is-small margin-bottom"
                  onClick={() => imageUploadRef.current.click()}
                >
                  Update Photo
                </button>

                <div className="field">
                  <label className="label">Email-Id</label>
                  <div className="control">
                    <input
                      readOnly
                      className="input"
                      placeholder="Email-Id"
                      type="text"
                      value={email}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">First Name</label>
                  <div className="control">
                    <input
                      required
                      className="input"
                      placeholder="First Name"
                      type="text"
                      value={firstName}
                      onChange={handleFirstName}
                      // onBlur={
                      //   firstName !== profile.firstName
                      //     ? userNameHandler
                      //     : () => {}
                      // }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Last Name</label>
                  <div className="control">
                    <input
                      required
                      className="input"
                      placeholder="Last Name"
                      type="text"
                      value={lastName}
                      onChange={handlerLastName}
                      // onBlur={
                      //   lastName !== profile.lastName
                      //     ? userNameHandler
                      //     : () => {}
                      // }
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">User Name</label>
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="User Name"
                      value={userName}
                      onChange={handleChangeUserName}
                      onBlur={handleChangeUserName}
                    />
                  </div>
                  {userName !== "" && (
                    <>
                      {isvaildUname && (
                        <p
                          className={
                            isvaildUname && isvaildUname.length > 0
                              ? `has-text-danger`
                              : `has-text-success`
                          }
                        >
                          {isvaildUname && isvaildUname.length > 0
                            ? `This ${userName} Not Available! Please try other one.`
                            : `Available!`}
                        </p>
                      )}
                    </>
                  )}
                </div>

                <div className="field">
                  <label className="label">Occupation</label>
                  <div className="control">
                    <input
                      maxLength={180}
                      className="input"
                      type="text"
                      placeholder="Occupation"
                      value={occupation}
                      onChange={(e) => setOccupation(e.target.value)}
                    />
                  </div>
                  <p>{`${180 - occupation.length} characters left.`}</p>
                </div>
                <div className="field">
                  <label className="label">Phone</label>
                  <div className="control">
                    <input
                      className="input"
                      type="tel"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label">Country</label>
                  <div className="control">
                    <CountrySelect
                      value={country[0]?.value}
                      multi={true}
                      flagImagePath="./assets/flags/"
                      onSelect={(val) => {
                        setCountry(val);
                      }}
                    />
                  </div>
                </div>
                <div className="label" style={{ alignSelf: "center" }}>
                  <label className="checkbox">
                    Show Flag{" "}
                    <input
                      type="checkbox"
                      name={"showflag"}
                      value={showflag}
                      checked={showflag}
                      onChange={(e) => setShowflag(!showflag)}
                    />
                  </label>
                </div>
              </section>
              <footer className="modal-card-foot">
                <button
                  type="submit"
                  className={`button is-rounded is-info ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Save
                </button>
                <button className="button is-rounded" onClick={closeModal}>
                  Cancel
                </button>
              </footer>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
}

export default EditProfileModal;
