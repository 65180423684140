import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function About() {
	return (
		<>
			<Header />
			<div className="page-body">
				<section className="section">
					<div className="container">
						<h2 className="title">Mission</h2>
						<div className="contents has-text-justified">
							<p>
								At briefT we understand that your time is precious and what you
								do with your time is even more. Our mission is to provide the
								opportunity to celebrate your life highlights through our
								platform in chronological and concise manner.
							</p>
							<br />
							<p>
								We do not believe that only celebrities should be celebrated, we
								believe everyone's good effort should be celebrated from any
								part of our planet earth.
							</p>
							<br />
							<h2 className="title">About Us</h2>
							<p>
								We are a group of professionals who admire every good effort of
								our fellow human beings no matter how big or small the outcome
								is.
							</p>
							<br />
							<p>
								At briefT we provide simplicity through our open platform that
								lets people create their own contents and contribute to the
								briefT’s mission.
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">Mission</h2>
						<div className="contents has-text-justified">
							<p>
								Chez briefT, nous comprenons que votre temps est précieux et ce
								que vous en faites est encore plus. Notre mission est de vous
								offrir l'opportunité de célébrer les moments forts de votre vie
								à travers notre plateforme de manière chronologique et concise.
							</p>
							<br />
							<p>
								Nous ne croyons pas que seules les célébrités doivent être
								célébrées, nous pensons que les bons efforts de chacun doivent
								être célébrés dans n'importe quelle partie de notre planète
								terre.
							</p>
							<br />
							<h2 className="title">À propos de nous</h2>
							<p>
								Nous sommes un groupe de professionnels qui admirent tous les
								bons efforts de nos semblables, quel que soit le résultat, grand
								ou petit.
							</p>
							<br />
							<p>
								Chez briefT, nous offrons la simplicité grâce à notre plateforme
								ouverte qui permet aux gens de créer leurs propres contenus et
								de contribuer à la mission de briefT.
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">مهمة</h2>
						<div className="contents has-text-right">
							<p>
								فی بریفت نحن نٌدرِكْ انَّ وقتَكمْ ثَمين وما تَقومونَ بِهِ في
								إِستِغْلالِ لهذا الوقت لا يُقَدَّرٌ بِثمن. مٌهِمَّتٌنا هِيَ
								إِعطاءٌ الفٌرصَة للإحتِفاءِ في حَياتٌكم ومٌنجزاتُكُم وذلك
								بإيجازِها بِشَكْلٍ مُتَسَلسِلٍ وبإسلوبٍ واضح.
							</p>
							<br />
							<p>
								نَحْنُ لا نُؤْمِنُ إنَّ الإحتفاءَ يجب ان يَكونَ للمشاهير فقط
								وانَّما نُؤْمنُ ان كل جُهد مُمَيز يجب ان يُحتفى به في اي ركنٍ من
								اركانِ المَعمورَة.
							</p>
							<br />
							<h2 className="title">معلومات عنا</h2>
							<p>
								نَحنُ مجموعةٌ مِنَ المحْتَرِفين الَّذِينَ يُقَدِّرونَ كُلَّ
								انجاز مُميز لأيِّ انسان آخر بغض النظَر اذا كانت نَتائِج هذا
								الإنجاز صغيرةً او كبيرة الخاص بهم الى.
							</p>
							<br />
							<p>
								في بریفت نحنُ نوفرُ البساطةَ من خِلالِ منصاتنا المفتوحة التي
								تَسمَحَ للأناس المشتركين من خلق اضافاتهم والإِسْهامْ مهَمَّةِ
								بریفت.
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">Mission</h2>
						<div className="contents has-text-justified">
							<p>
								Wir bei briefT verstehen, dass Ihre Zeit wertvoll, und dass was
								Sie mit Ihrer Zeit machen, umso wertvoller ist. Unsere Aufgabe
								ist es, Ihnen die Gelegenheit zu bieten, eure Höhepunkte im
								Leben über unsere Plattform chronologisch und präzise zu
								zelebrieren.
							</p>
							<br />
							<p>
								Wir glauben nicht, dass nur Prominente gefeiert werden sollten.
								Wir glauben, dass jede gute Bemühung, von jedem Teil unserer
								Welt gefeiert werden sollte.
							</p>
							<br />
							<h2 className="title">Über uns</h2>
							<p>
								Wir sind eine Gruppe von Fachleuten, die jede gute Leistung
								unserer Mitmenschen bewundern, egal wie groß oder klein das
								Ergebnis ist.
							</p>
							<br />
							<p>
								Bei briefT bieten wir Einfachheit durch unsere offene Plattform,
								tfff mit der Menschen ihre eigenen Inhalte erstellen und zur
								Mission von briefT beitragen können.
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">ماموریت ما</h2>
						<div className="contents has-text-right">
							<p>
								ما درک میکنیم که وقت شما با ارزش است و کاری را که با وقت خود
								انجام می دهید حتی با ارزش تر است. مأموریت ما این است که فرصتی را
								برای شما فراهم بسازیم تا نکات برجسته در زندگی خود را از طریق
								سکوی ما به روشی دقیق و مختصر ارائه دهید و آنرا جشن بگیرید.
							</p>
							<br />
							<p>
								ما اعتقاد نداریم که فقط افراد مشهور باید جشن بگیرند، بلکه ما
								معتقدیم که تلاش خوب همه باید از هر بخشی از سیاره زمین ما جشن
								گرفته شود.
							</p>
							<br />
							<h2 className="title">درباره ما</h2>
							<p>
								ما یک گروهی حرفه ای هستیم که هر تلاش خوب همنوعان خود را تحسین می
								کنیم بدون توجه به اینکه نتیجه این تلاش چقدر بزرگ و یا کوچک است.
							</p>
							<br />
							<p>
								ما سادگی اراهه معلومات را از طریق سکوی باز خود فراهم می کنیم که
								همچنان به افراد اجازه می دهد تا محتوای خود را ایجاد کرده و
								درکامیابی ماموریت ما اشتراک کنند.
							</p>
						</div>
					</div>
				</section>
				
				<section className="section">
					<div className="container">
						<h2 className="title">我們的任務</h2>
						<div className="contents has-text-justified">
							<p>
								在 briefT，我們了解您的時間是寶貴的，怎麼樣
								而您所花費的時間甚至更多。
								我們的使命是通過時間順序和簡潔的方式，為您提供機會慶祝您的生活亮點。我們不認為只應該慶祝名人，
								我們相信應該從地球上的任何地方慶祝每個人的努力。
							</p>

							<br />
							<h2 className="title">關於我們</h2>
							<p>
								我們是一群敬業的專家，無論成果大小，我們都欽佩同胞的一切努力。
								在briefT，我們通過開放平台提供了簡單性，使人們可以創建自己的內容並為briefT的使命做出貢獻
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">η αποστολή μας</h2>
						<div className="contents has-text-justified">
							<p>
								Στο briefT αντιλαμβανόμαστε ότι ο χρόνος σας είναι πολύτιμος και
								τι κάνετε με το χρόνο σας είναι ακόμα περισσότερο. Η αποστολή
								μας είναι να δώσουμε την ευκαιρία να γιορτάσουμε τη ζωή σας
								τονίζει μέσα από την πλατφόρμα μας με χρονολογική και συνοπτική
								τρόπο.
							</p>
							<br />
							<p>
								Δεν πιστεύουμε ότι μόνο διασημότητες θα πρέπει να εορτάζεται,
								πιστεύουμε ότι η καλή προσπάθεια όλων πρέπει να γιορτάζεται από
								οποιοδήποτε μέρος του πλανήτη γη μας.
							</p>
							<br />
							<h2 className="title">Σχετικά με εμάς</h2>
							<p>
								Είμαστε μια ομάδα επαγγελματιών που θαυμάζουν κάθε καλή
								προσπάθεια των συνανθρώπων μας, ανεξάρτητα από το πόσο μεγάλο ή
								μικρό είναι το αποτέλεσμα.
							</p>
							<br />
							<p>
								Στο briefT παρέχουμε απλότητα μέσω της ανοιχτής πλατφόρμας που
								επιτρέπει στους ανθρώπους να δημιουργούν το δικό τους
								περιεχόμενο και να συμβάλλουν στην αποστολή του briefT.
							</p>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container">
						<h2 className="title">Misión</h2>
						<div className="contents has-text-justified">
							<p>
								En briefT entendemos que tu tiempo es precioso y lo que haces
								con él es aún más importante. Nuestra misión es proveer la
								oportunidad de celebrar los grandiosos momentos de tu vida a
								través de nuestra plataforma de una manera cronológica y
								concisa.
							</p>
							<br />
							<p>
								No creemos que sólo las celebridades puedan celebrar, creemos
								que los esfuerzos de cada uno pueden ser festejados desde
								cualquier parte de nuestro planeta tierra.
							</p>
							<br />
							<h2 className="title">Sobre Nosotros</h2>
							<p>
								Somos un grupo de profesionales que admira el gran esfuerzo de
								cada uno de nuestros hermanos sin importar que tan grande o
								pequeño sea el resultado.
							</p>
							<br />
							<p>
								En briefT proveemos simplicidad a través de una plataforma
								abierta que permite a la gente crear sus propios contenidos y a
								la vez contribuir con la misión de briefT.
							</p>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	);
}

export default About;
