import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useReactRouter from "use-react-router";

import { Store } from "../Store";
import PATHS from "../routePaths";
import logo from "../images/logo2.png";
import EditProfileModal from "./EditProfileModal";

const toggleNavMenu = ({ target }) => {
  // Get the target from the "data-target" attribute
  const $target = document.getElementById(target.dataset.target);
  if (!$target) return;
  // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
  target.classList.toggle("is-active");
  $target.classList.toggle("is-active");
};

function Header() {
  const history = useHistory();

  //console.log("history >>", history);

  const {
    match: { path },
  } = useReactRouter();
  const { profile } = useContext(Store);
  //const [myprofile, setMyprofile] = useState(false);
  const [isEditProfileShown, setIsEditProfileShown] = useState(false);
  const [isactive, setIsactive] = useState(false);

  const divref = useRef(null);

  const openProfile_Handler = () => {
    const state = { id: profile._id };
    //history.push(PATHS.profile);
    history.push(`/${profile.userName}`, state);
    setIsactive(false);
  };

  let menu = (
    <>
      <Link
        className={`navbar-item ${path === "/signin" ? "is-active" : ""}`}
        to={PATHS.signin}
      >
        Sign In
      </Link>
      <Link
        className={`navbar-item ${path === "/signup" ? "is-active" : ""}`}
        to={PATHS.signup}
      >
        Register
      </Link>
    </>
  );

  if (profile) {
    menu = (
      <>
        {/* <Link className={`navbar-item ${path === '/profile' ? 'is-active' : ''}`} to={PATHS.profile}>{profile.firstName + " " + profile.lastName}</Link> */}
        {/* <div className={`dropdown ${myprofile ? 'is-active' : ''}`}>
        <div className="dropdown-trigger">
          <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={()=>setMyprofile( ! myprofile)} >
            <span>{profile.firstName + " " + profile.lastName}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a href="#" className="dropdown-item">
              Dropdown item
            </a>
          </div>
        </div>
      </div> */}
        <div className={`navbar-item dropdown`}>
          <div
            ref={divref}
            className={`dropdown is-right custom-dropdown ${
              isactive ? "is-active" : ""
            }`}
          >
            <div className="dropdown-trigger">
              <button
                className="button dropbtn"
                aria-haspopup="true"
                aria-controls="dropdown-menu6"
                onClick={() => setIsactive(!isactive)}
              >
                {profile.firstName + " " + profile.lastName}
              </button>
            </div>
            <div
              className="dropdown-menu"
              id="dropdown-menu-header"
              role="menu"
            >
              <div className="dropdown-content custom-content">
                <button
                  className={`dropdown-item`}
                  onClick={openProfile_Handler}
                >
                  {" "}
                  Profile
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => setIsEditProfileShown(true)}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          </div>
        </div>

        {isEditProfileShown && (
          <EditProfileModal onClose={() => setIsEditProfileShown(false)} />
        )}

        <Link
          className={`navbar-item ${path === "/signout" ? "is-active" : ""}`}
          to={PATHS.signout}
        >
          Sign Out
        </Link>
      </>
    );
  }

  const handleClickOutside = (event) => {
    //console.log("divref >>>", divref);
    //console.log("divref > contains >>", divref.current);
    //console.log("ref.current.contains >>");
    if (divref && !divref?.current?.contains(event.target)) {
      //setMyprofile(false);
      setIsactive(false);
    }
  };

  return (
    <nav
      className="navbar is-fixed-top is-light has-shadow"
      role="navigation"
      aria-label="main navigation"
      onMouseDown={handleClickOutside}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to={PATHS.home} title="Main Page">
            <img alt="Brieft Logo" className="logo" src={logo} />
          </Link>
          <span
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarMenu"
            onClick={toggleNavMenu}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>
        <div id="navbarMenu" className="navbar-menu">
          <div className="navbar-end">
            <Link
              className={`navbar-item ${path === "/" ? "is-active" : ""}`}
              to="/"
            >
              Home
            </Link>
            {menu}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
