import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useContext } from "react";
import Message from "../component/Message/Message";
import ReactScrollToBottom from "react-scroll-to-bottom";
import closeIcon from "../images/closeIcon.png";
import { Store } from "../Store";
import { database } from "../stitch";
import { ObjectId } from "bson";
import Loading from "../components/Loading";

//let socket;

function Chat() {
  const { profile, setStore } = useContext(Store);
  //console.log("messages: ", profile, profile._id.toString())
  const [messages] = useState(profile.messages);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [selectedprofileobj, setSelectedprofileobj] = useState({});
  const [selProfMsgobj, setSelProfMsgobj] = useState();
  const [loading, setLoading] = useState(false);
  const prodileobj = profile;

  const handleClick = async (currentTab) => {
    setLoading(true);
    setCurrentIndex(currentTab);
    //console.log(" messages[currentTab].id: ", messages[currentTab]);

    const pcheckid = database
      .collection("profiles")
      .find({ _id: ObjectId(messages[currentTab].id) })
      .asArray();
    const pcount = await pcheckid.then((result) => result);
    //console.log("pcount: ", pcount)
    setSelectedprofileobj(pcount[0]);
    //const selectedprofileobj=selectedProfile;
    const getMsgObj = pcount[0].messages.find(
      (it) => it.id.toString() === profile._id.toString()
    );
    //console.log("getMsgObj: ", getMsgObj)
    setSelProfMsgobj(getMsgObj);
    setLoading(false);
    console.log("setSelProfMsgobj: ", selProfMsgobj);
  };

  const send = async () => {
    setLoading(true);
    const message = document.getElementById("chatInput").value;
    //sender Object
    const senderObj = {
      create_Date: new Date().toString(),
      message: message,
      userid: profile._id.toString(),
      sendername: `${profile.firstName} ${profile.lastName}`,
      recivername: `${selectedprofileobj.firstName} ${selectedprofileobj.lastName}`,
    };
    const receiverObj = {
      create_Date: new Date().toString(),
      message: message,
      userid: selectedprofileobj._id.toString(),
      sendername: `${profile.firstName} ${profile.lastName}`,
      recivername: `${selectedprofileobj.firstName} ${selectedprofileobj.lastName}`,
    };

    if ("messages" in prodileobj) {
      //console.log("profile.messages: ", prodileobj.messages)
      const pcheckid = database.collection("profiles").count({
        _id: prodileobj._id,
        "messages.id": selectedprofileobj?._id.toString(),
      });
      const pcount = await pcheckid.then((result) => result);
      //console.log("pcount: ", pcount)
      if (pcount > 0) {
        await database.collection("profiles").updateOne(
          {
            _id: prodileobj._id,
            "messages.id": selectedprofileobj?._id.toString(),
          },
          { $addToSet: { "messages.$.chat": senderObj } }
        );
      } else {
        const msgObj = {
          id: selectedprofileobj._id.toString(),
          name: `${selectedprofileobj.firstName} ${selectedprofileobj.lastName}`,
          chat: [senderObj],
        };
        await database
          .collection("profiles")
          .updateOne({ _id: prodileobj._id }, { $push: { messages: msgObj } });
      }
    } else {
      const msgObj = {
        id: selectedprofileobj._id.toString(),
        name: `${selectedprofileobj.firstName} ${selectedprofileobj.lastName}`,
        chat: [senderObj],
      };
      prodileobj["messages"] = [msgObj];
      await database
        .collection("profiles")
        .updateOne({ _id: prodileobj._id }, { $set: prodileobj });
    }
    //console.log("profile messages:", prodileobj)

    //await database.collection('profiles').updateOne({ _id: profile._id }, {  $set: profile  });
    //reciver Object

    if ("messages" in selectedprofileobj) {
      //console.log("message: ", selectedprofileobj?.messages);
      const scheckid = database.collection("profiles").count({
        _id: selectedprofileobj._id,
        "messages.id": prodileobj?._id.toString(),
      });
      const scount = await scheckid.then((result) => result);
      // console.log('scount:', scount)
      if (scount > 0) {
        await database.collection("profiles").updateOne(
          {
            _id: selectedprofileobj._id,
            "messages.id": prodileobj?._id.toString(),
          },
          { $addToSet: { "messages.$.chat": receiverObj } }
        );
      } else {
        const msgObj = {
          id: profile._id.toString(),
          name: `${profile.firstName} ${profile.lastName}`,
          chat: [receiverObj],
        };
        await database
          .collection("profiles")
          .updateOne(
            { _id: selectedprofileobj._id },
            { $push: { messages: msgObj } }
          );
      }
    } else {
      const msgObj = {
        id: profile._id.toString(),
        name: `${profile.firstName} ${profile.lastName}`,
        chat: [receiverObj],
      };
      selectedprofileobj["messages"] = [msgObj];
      await database
        .collection("profiles")
        .updateOne(
          { _id: selectedprofileobj._id },
          { $set: selectedprofileobj }
        );
    }
    setLoading(false);

    document.getElementById("chatInput").value = "";

    profile.messages[currentIndex].chat.push(senderObj);
    setStore("profile", profile);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Header />
      <div className="page-body">
        <div className="chatPage">
          <div className="chatContainer">
            <div className="header">
              <h2>Messages</h2>
              <a href="/">
                {" "}
                <img src={closeIcon} alt="Close" />
              </a>
            </div>
            {/* <div className="chatBox">

                </div> */}
            <div style={{ boxSizing: "border-box" }}>
              <div className="tab">
                {messages?.map(
                  (item, i) => (
                    <button
                      key={item.name}
                      className={`tablinks ${
                        currentIndex === i ? "active" : ""
                      }`}
                      onClick={() => handleClick(i)}
                    >
                      {item.name}
                    </button>
                  )

                  // <li key={i} >
                  // <div className='lirow'>
                  //     <div className='lidivpadding'>{item.name}</div>
                  //     <div style={{paddingLeft:"10px"}}>{item.chat[0].message}</div>
                  // </div>
                  // </li>
                )}
              </div>

              <div className="tabcontent ">
                {currentIndex !== -1 && (
                  <>
                    <div className="chatBox" style={{ overflow: "auto" }}>
                      {messages[currentIndex]?.chat.reverse().map((item, i) => (
                        <>
                          <ReactScrollToBottom>
                            <Message
                              user={item.sendername}
                              message={item.message}
                            />
                          </ReactScrollToBottom>
                        </>
                      ))}
                    </div>

                    <div className="inputBox">
                      <input
                        onKeyPress={(event) =>
                          event.key === "Enter" ? send() : null
                        }
                        type="text"
                        id="chatInput"
                      />
                      <button onClick={send} className="sendBtn">
                        Send
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Chat;
