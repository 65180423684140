import React, { useState, useContext } from "react";
import { database } from "../stitch";
import { ObjectId } from "bson";
import { Store } from "../Store";
import toast from "react-hot-toast";

export default function DeleteMessage(props) {
  const { activity, sortedActivities } = props;
  const { profile, setStore } = useContext(Store);
  const [isactive, setIsactive] = useState(false);
  const [ismodalactive, setIsmodalactive] = useState(false);
  //const { item, index, showdeleteMenu } = props;

  //console.log("item >>", item);

  async function deleteMessage_Handler() {
    try {
      //console.log("activity >>>", activity);
      //console.log("index >>>", index);
      const findObj = sortedActivities.filter(
        (item) => item.id !== activity.id
      );
      //console.log("findObj >>", findObj);
      await database
        .collection("profiles")
        .updateOne(
          { _id: ObjectId(profile._id) },
          { $set: { timelineMsg: findObj } }
        );
      profile.timelineMsg = findObj;
      setStore("profile", profile);
      setIsmodalactive(false);
      setIsactive(false);
      toast("Message deleted successfully.");
    } catch {
      console.warn("Some Error!");
      setIsmodalactive(false);
      setIsactive(false);
    }
  }

  return (
    <>
      <div className={`dropdown`}>
        <div className={`dropdown is-right ${isactive ? "is-active" : ""}`}>
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu6"
              onClick={() => setIsactive(!isactive)}
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu6" role="menu">
            <div className="dropdown-content is-gapless">
              <div className="dropdown-item">
                <button
                  className="button is-outlined is-full-button"
                  data-target="modal-ter"
                  aria-haspopup="true"
                  onClick={() => setIsmodalactive(true)}
                >
                  Delete Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`modal ${ismodalactive ? "is-active" : ""}`}
        id={"modal-ter"}
      >
        <div className="modal-background"></div>
        <div className="modal-card custom-modal-card">
          <header
            className="modal-card-head content-modal-card-head"
            style={{ justifyContent: "right" }}
          >
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setIsmodalactive(false);
                setIsactive(false);
              }}
            ></button>
          </header>
          <section className="modal-card-body">
            <div className="content-modal">
              {/* <div className="sizefont">
                <i className="fas fa-exclamation-circle"></i>
              </div> */}
              <h2>Are you sure!</h2>
              <p>Do you want to delete this message?</p>
              <div className="butons-modal">
                <button
                  className="button is-success"
                  onClick={deleteMessage_Handler}
                >
                  Yes
                </button>
                <button
                  className="button"
                  onClick={() => {
                    setIsmodalactive(false);
                    setIsactive(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
