import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SignUpBody from '../components/SignUpBody';

function Signup() {
  return (
    <>
      <Header />
      <div className="page-body"><SignUpBody /></div>
      <Footer />
    </>
  );
}

export default Signup;