import React from "react";
import { database } from "../stitch";
import { ObjectId } from "bson";
import { useContext } from "react";
import { Store } from "../Store";
import { TryFollow } from "../component/TryFollow";

function GolobalFollowerButton({ agent, profile, agents ,setAgents, index }) {

  const { setStore } = useContext(Store);
  const [enableTryModal, setEnableTryModal] = React.useState(false);
  const [tryUserName, setTryUserName] = React.useState("");

  const isUserFollowing = (userObj) => {
    const tempProfile = { ...profile };

    if (tempProfile && tempProfile.following) {
      const tempObj = tempProfile.following.filter(
        (item) => item?.userid?.toString() === userObj?._id?.toString()
      );

      return tempObj.length > 0 ? true : false;
    }
    return false; // User is not following
  };

  //For Serach Profile Add and Remove Follower
  const searchProfileFollowerHandler = async (
    searchedProfile,
    loginProfile
  ) => {
    const saveObj = {
      userid: ObjectId(loginProfile._id),
      name: loginProfile.firstName,
      userName: loginProfile.userName,
      image: loginProfile.image,
      createat: new Date(),
    };
    let tempObj =
      searchedProfile?.followers?.length > 0
        ? [...searchedProfile?.followers]
        : [];
    if (
      searchedProfile?.followers?.filter(
        (item) => item.userid?.toString() === loginProfile._id?.toString()
      ).length === 0
    ) {
      tempObj = [...tempObj, saveObj];
      //setFollowing(true);
    } else {
      tempObj =
        searchedProfile?.followers?.length > 0
          ? searchedProfile?.followers?.filter(
              (item) => item.userid?.toString() !== loginProfile._id?.toString()
            )
          : [saveObj];
      //setFollowing(false);
    }
    searchedProfile.followers = tempObj;



    //setAgents(searchedProfile)



    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(searchedProfile._id) },
        { $set: { followers: tempObj } }
      );

    //setSelectedProfile(searchedProfile);
  };

  //For Login Profile Add and Remove Following
  const loginProfileFollowingHandler = async (
    searchedProfile,
    loginProfile
  ) => {
    const saveObj = {
      userid: ObjectId(searchedProfile._id),
      name: searchedProfile.firstName,
      userName: searchedProfile.userName,
      image: searchedProfile.image || "",
      createat: new Date(),
    };
    let tempObj =
      loginProfile?.following?.length > 0 ? [...loginProfile?.following] : [];
    if (
      loginProfile?.following?.filter(
        (item) => item.userid?.toString() === searchedProfile._id?.toString()
      ).length === 0
    ) {
      tempObj = [...tempObj, saveObj];
    } else {
      tempObj =
        loginProfile?.following?.length > 0
          ? loginProfile?.following?.filter(
              (item) =>
                item.userid?.toString() !== searchedProfile._id?.toString()
            )
          : [saveObj];
    }
    profile.following = tempObj;
    setStore(profile);
    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(loginProfile._id) },
        { $set: { following: tempObj } }
      );
  };

  const updateFollowingAndFollowers = async (userToFollow, userFollowing) => {
    if (profile) {
      const searchedProfile = { ...userToFollow };
      const loginProfile = { ...userFollowing };
      // Serach Profile Add and Remove Follower
      await searchProfileFollowerHandler(searchedProfile, loginProfile);
      await loginProfileFollowingHandler(searchedProfile, loginProfile);
    } else {
      const tempusernme =
        userToFollow && userToFollow.firstName + " " + userToFollow.lastName;
      setEnableTryModal(true);
      setTryUserName(tempusernme);
    }
  };

  console.log("isUserFollowing(agent) >>", isUserFollowing(agent))

  return (
    <>
      {profile?._id?.toString() !== agent._id?.toString() && (
        <button
          onClick={() => updateFollowingAndFollowers(agent, profile)}
          className={`${
            isUserFollowing(agent)
              ? "button has-background-grey-lighter "
              : "button postMessagebg"
          }`}
        >
          {isUserFollowing(agent) ? "Following" : "Follow"}
        </button>
      )}
      {enableTryModal && (
        <TryFollow
          enableTryModal={enableTryModal}
          setEnableTryModal={setEnableTryModal}
          userName={tryUserName}
          setTryUserName={setTryUserName}
        />
      )}
    </>
  );
}

export default GolobalFollowerButton;
