import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import useReactRouter from "use-react-router";

import { Store } from "../Store";
import {
  UserPasswordCredential,
  AnonymousCredential,
} from "mongodb-stitch-browser-sdk";
import { stitchClient, database } from "../stitch";

function SigninBody() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    history,
    location: { state },
  } = useReactRouter();
  const { setStore } = useContext(Store);

  const signin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError(null);
      if (email.length > 0) {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)+$/.test(email)) {
          await stitchClient.auth.loginWithCredential(
            new UserPasswordCredential(email, password)
          );
          const profile = await database
            .collection("profiles")
            .findOne({ email });
          setLoading(false);
          if (!profile) {
            return setError("Profile not initialized");
          }
          setStore("profile", profile);
          const state = { id: profile._id };
          //history.push(PATHS.profile);
          history.push(`/${profile.userName}`, state);
          //history.push("/profile");
        } else {
          const userpass = password;
          await stitchClient.auth.loginWithCredential(
            new AnonymousCredential()
          );
          const getEmailid = await database
            .collection("profiles")
            .findOne({ userName: email });

          //console.log("getEmailid >>>", getEmailid);
          if (getEmailid) {
            await stitchClient.auth.loginWithCredential(
              new UserPasswordCredential(getEmailid.email, userpass)
            );
            const profile = await database
              .collection("profiles")
              .findOne({ email: getEmailid.email });
            setLoading(false);
            if (!profile) {
              return setError("Profile not initialized");
            }
            setStore("profile", profile);
            history.push("/profile");
          } else {
            setLoading(false);
            return setError("Profile not initialized");
          }
        }
      } else {
        setLoading(false);
        return setError("Please enter valid email / user Name.");
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  };

  return (
    <section className="section">
      <div className="container compact">
        <h1 className="title">Sign In</h1>
        {error && <div className="notification is-danger">{error}</div>}
        {!error && state && state.signup && (
          <div className="notification is-info">
            Please verify your email address to complete registration
          </div>
        )}
        {!error && state && state.verified && (
          <div className="notification is-info">
            Your email address has been verified successfully
          </div>
        )}
        {!error && state && state.passwordReset && (
          <div className="notification is-info">
            You new password has been set
          </div>
        )}
        <form onSubmit={signin}>
          <fieldset disabled={loading}>
            <div className="field">
              <label className="label">Email/User Name</label>
              <div className="control">
                {/* <input
                  required
                  className="input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                <input
                  required
                  placeholder="Email/User Name"
                  className="input"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input
                  required
                  placeholder="Password"
                  className="input"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  type="submit"
                  className={`button is-rounded is-info ${
                    loading ? "is-loading" : ""
                  }`}
                >
                  Sign In
                </button>
                <Link className="reset-password" to="/reset-password">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  );
}

export default SigninBody;
