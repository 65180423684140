import React from "react";
import { database } from "../stitch";
import { Store } from "../Store";
import { ObjectId } from "bson";
import { TryFollow } from "../component/TryFollow";
//import { useEffect } from "react";

function FollowButton(props) {
  //console.log("props Follow Button >>>", props);

  const { profile, setStore } = React.useContext(Store);
  const { isMyProfile, searchProfile, setSelectedProfile } = props;
  const [following, setFollowing] = React.useState(false);
  const [isBackFollowing, setIsBackFollowing] = React.useState(false);

  const [enableTryModal, setEnableTryModal] = React.useState(false);
  const [tryUserName, setTryUserName] = React.useState("");

  const checkFollowing = () => {
    //console.log("searchProfile?.following >>", searchProfile);
    if (searchProfile?.followers?.length > 0) {
      const tempObj = searchProfile?.followers?.filter(
        (item) => item.userid?.toString() === profile?._id.toString()
      ).length;
      //console.log("tempObj>>>>>>>>>>>>>>>>>>", tempObj);
      const followbool = tempObj > 0 ? true : false;
      //console.log("followbool>>>>>>>>>>.>>", followbool);
      setFollowing(followbool);
    } else {
      setFollowing(false);
    }

    if (searchProfile?.following?.length > 0) {
      const tempIsBackFollowingObj = searchProfile?.following?.filter(
        (item) => item.userid?.toString() === profile?._id.toString()
      ).length;
      const boolObj = tempIsBackFollowingObj > 0 ? true : false;
      //console.log("backFollow>>>", boolObj);
      setIsBackFollowing(boolObj);
    } else {
      setIsBackFollowing(false);
    }
  };

  //For Serach Profile Add and Remove Follower
  const searchProfileFollowerHandler = async (
    searchedProfile,
    loginProfile
  ) => {
    const saveObj = {
      userid: ObjectId(loginProfile._id),
      name: loginProfile.firstName + " " + loginProfile.lastName, //loginProfile.firstName,
      userName: loginProfile.userName,
      image: loginProfile.image,
      createat: new Date(),
    };
    let tempObj =
      searchedProfile?.followers?.length > 0
        ? [...searchedProfile?.followers]
        : [];
    if (
      searchedProfile?.followers?.filter(
        (item) => item.userid?.toString() === loginProfile._id?.toString()
      ).length === 0
    ) {
      tempObj = [...tempObj, saveObj];
      setFollowing(true);
    } else {
      tempObj =
        searchedProfile?.followers?.length > 0
          ? searchedProfile?.followers?.filter(
              (item) => item.userid?.toString() !== loginProfile._id?.toString()
            )
          : [saveObj];
      setFollowing(false);
    }

    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(searchedProfile._id) },
        { $set: { followers: tempObj } }
      );

    searchedProfile.followers = tempObj;
    setSelectedProfile(searchedProfile);
  };

  //For Login Profile Add and Remove Following
  const loginProfileFollowingHandler = async (
    searchedProfile,
    loginProfile
  ) => {
    const saveObj = {
      userid: ObjectId(searchedProfile._id),
      name: searchedProfile.firstName + " " + searchedProfile.lastName, //searchedProfile.firstName,
      userName: searchedProfile.userName,
      image: searchedProfile.image,
      createat: new Date(),
    };
    let tempObj =
      loginProfile?.following?.length > 0 ? [...loginProfile?.following] : [];
    if (
      loginProfile?.following?.filter(
        (item) => item.userid?.toString() === searchedProfile._id?.toString()
      ).length === 0
    ) {
      tempObj = [...tempObj, saveObj];
    } else {
      tempObj =
        loginProfile?.following?.length > 0
          ? loginProfile?.following?.filter(
              (item) =>
                item.userid?.toString() !== searchedProfile._id?.toString()
            )
          : [saveObj];
    }
    profile.following = tempObj;
    setStore(profile);
    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(loginProfile._id) },
        { $set: { following: tempObj } }
      );
  };

  const followerHandler = async () => {
    if (profile) {
      const searchedProfile = { ...searchProfile };
      const loginProfile = { ...profile };
      // Serach Profile Add and Remove Follower
      await searchProfileFollowerHandler(searchedProfile, loginProfile);
      await loginProfileFollowingHandler(searchedProfile, loginProfile);
    } else {
      //alert("Please Sign in / Sign up to your account to access profile.");
      const tempusernme = searchProfile
        ? searchProfile.firstName + " " + searchProfile.lastName
        : "";
      //console.log("tempusernme >((()))>>>", searchProfile, tempusernme);
      setEnableTryModal(true);
      setTryUserName(tempusernme);
      return false;
    }
  };

  React.useEffect(() => {
    checkFollowing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProfile]);

  //console.log("following >>>", following);

  //console.log("isBackFollowing >>>", isBackFollowing);

  return (
    <div style={{ marginTop: "26px" }}>
      {!isMyProfile && (
        <button
          className={`${following ? "button is-dark" : "button postMessagebg"}`}
          onClick={followerHandler}
        >
          {following && isBackFollowing
            ? "Unfollow"
            : !following && isBackFollowing
            ? "Follow Back"
            : "Follow +"}
        </button>
      )}
      {/* {!isMyProfile && !isBackFollowing ? <>Unfollow</> : <>Follow Back</>} */}
      {enableTryModal && (
        <TryFollow
          enableTryModal={enableTryModal}
          setEnableTryModal={setEnableTryModal}
          userName={tryUserName}
          setTryUserName={setTryUserName}
        />
      )}
    </div>
  );
}

export default FollowButton;
