import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bulma/css/bulma.min.css";
//import "./bulma.sass";
import "./main.css";
import { stitchClient, database } from "./stitch";
import RoutePaths from "./routePaths";
import Home from "./views/Home";
import About from "./views/About";
import LoadingPage from "./components/LoadingPage";
import Signup from "./views/Signup";
import Signin from "./views/Signin";
import ConfirmEmail from "./views/ConfirmEmail";
import ResetPassword from "./views/ResetPassword";
import SetupNewPassword from "./views/SetupNewPassword";
import Signout from "./views/Signout";
import Profile from "./views/Profile";
import SearchResult from "./views/SearchResult";
import Privacy from "./views/Privacy";
import Contact from "./views/Contact";
import Terms from "./views/Terms";
import Chat from "./views/Chat";
import PostMessageView from "./views/PostMessageView";
import { Store } from "./Store";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      loading: stitchClient.auth.isLoggedIn,
      setStore: (key, val) => {
        this.setState({ [key]: val });
      },
    };
  }

  async componentDidMount() {
    if (stitchClient.auth.isLoggedIn) {
      if (stitchClient.auth.user.profile.email) {
        const profile = await database
          .collection("profiles")
          .findOne({ email: stitchClient.auth.user.profile.email });
        this.setState({ profile });
        ///console.log(profile, "<<<profile");
      } else {
        await stitchClient.auth.logout();
      }
      this.setState({ loading: false });
    }
  }

  render() {
    if (this.state.loading) return <LoadingPage />;
    return (
      <Store.Provider value={this.state}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={RoutePaths.home}
              render={(props) => <Home {...props} />}
            />
            <Route
              exact
              path={RoutePaths.contact}
              render={(props) => <Contact {...props} />}
            />
            <Route
              exact
              path={RoutePaths.about}
              render={(props) => <About {...props} />}
            />
            <Route
              exact
              path={RoutePaths.privacy}
              render={(props) => <Privacy {...props} />}
            />
            <Route
              exact
              path={RoutePaths.terms}
              render={(props) => <Terms {...props} />}
            />
            <Route
              exact
              path={RoutePaths.signup}
              render={(props) => <Signup {...props} />}
            />
            <Route
              exact
              path={RoutePaths.signin}
              render={(props) => <Signin {...props} />}
            />
            <Route
              exact
              path={RoutePaths.signout}
              render={(props) => <Signout {...props} />}
            />
            <Route
              exact
              path={RoutePaths.confirmEmail}
              render={(props) => <ConfirmEmail {...props} />}
            />
            <Route
              exact
              path={RoutePaths.resetPassword}
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path={RoutePaths.setupNewPassword}
              render={(props) => <SetupNewPassword {...props} />}
            />
            <Route
              exact
              path={RoutePaths.searchResults}
              render={(props) => <SearchResult {...props} />}
            />
            <Route
              exact
              path={RoutePaths.profile}
              render={(props) => <Profile {...props} />}
            />
            <Route
              exact
              path={RoutePaths.profileWithId}
              render={(props) => <Profile {...props} />}
            />
            <Route
              exact
              path={RoutePaths.chat}
              render={(props) => <Chat {...props} />}
            />
            <Route
              exact
              path={RoutePaths.PostMessageView}
              render={(props) => <PostMessageView {...props} />}
            />
          </Switch>
        </BrowserRouter>
      </Store.Provider>
    );
  }
}

export default App;
