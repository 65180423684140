const PATHS = {
  home: "/",
  about: "/about",
  contact: "/contact",
  privacy: "/privacy",
  terms: "/terms",
  signin: "/signin",
  signup: "/signup",
  confirmEmail: "/confirm-email",
  resetPassword: "/reset-password",
  setupNewPassword: "/setup-new-password",
  signout: "/signout",
  addClients: "/addClients",
  addActivity: "/addActivity/:clientEmail",

  profile: "/profile",
  profileWithId: "/:id",
  clientDetails: "/clientDetails/:clientEmail",

  searchResults: "/search/:query",
  chat: "/chat",
  postMessage: "./postmessage",
};

export default PATHS;
