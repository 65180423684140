import React, { useEffect } from 'react';

function ContentsPage({ title, contents }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);
  return (
    <section className="section">
      <div className="container">
        <h2 className="title">{title}</h2>
        <div className="contents has-text-justified">{contents}</div>
      </div>
    </section>
  );
}

export default ContentsPage;