import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import TextTransition, { presets } from "react-text-transition";

import logo from "../images/logo.png";

const TEXTS = [
  "Celebrate Your Efforts and Milestone's! ",
  "Vier u pogings en mylpaal! ",
  "Festoni përpjekjet dhe momentin tuaj historik! ",
  "Celebrate Your Efforts and Milestone's! ",
  " ጥረቶችዎን እና የወቅቱን በዓል ያክብሩ!",
  " احتفل بجهودك وإنجازك! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Նշեք ձեր ջանքերն ու իրադարձությունները! ",
  " Səylərinizi və dönəminizi qeyd edin! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Святкуйце свае намаганні і важныя этапы! ",
  " আপনার প্রচেষ্টা এবং মাইলস্টোন উদযাপন করুন!",
  " Celebrate Your Efforts and Milestone's! ",
  " Празнувайте вашите усилия и важни събития! ",
  "庆祝您的努力和里程碑 ",
  " Celebrate Your Efforts and Milestone's! ",
  " Proslavite svoje napore i prekretnice! ",
  " Oslavte své úsilí a milník! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Fejr din indsats og milepæl! ",
  " Tähistage oma pingutusi ja verstaposti! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ipagdiwang ang Iyong Mga Pagsisikap at Milyahe! ",
  "Célébrez vos efforts et votre jalon! ",
  " Celebrate Your Efforts and Milestone's! ",
  " იზეიმეთ თქვენი ძალისხმევა და ეტაპი!",
  " Feiern Sie Ihre Bemühungen und Ihren Meilenstein! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Gubha imizamo yakho nengqophamlando yakho! ",
  " તમારા પ્રયત્નો અને માઇલ સ્ટોન ઉજવો! ",
  " Celebrate Your Efforts and Milestone's! ",
  " חגגו את המאמצים שלכם ואת ציון הדרך! ",
  " अपने प्रयासों और मील का पत्थर का जश्न मनाने! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ünnepelje erőfeszítéseit és mérföldkövét! ",
  " Fagnaðu viðleitni þinni og áfanga! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Rayakan Upaya dan Pencapaian Anda! ",
  " Ceiliúradh a dhéanamh ar d‘iarrachtaí agus ar chloch mhíle! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Celebrate i vostri sforzi e il traguardo! ",
  "あなたの努力とマイルストーンを祝おう！",
  " Celebrate Your Efforts and Milestone's! ",
  " ನಿಮ್ಮ ಪ್ರಯತ್ನಗಳು ಮತ್ತು ಮೈಲಿಗಲ್ಲನ್ನು ಆಚರಿಸಿ! ",
  " Сіздің күш-жігеріңіз бен белесіңізді тойлаңыз! ",
  " Celebrate Your Efforts and Milestone's! ",
  "귀하의 노력과 이정표를 축하하십시오! ",
  " Magno nisu Ecclesiae conatae tui celebramus et Fabella! ",
  " Celebrate Your Efforts and Milestone's! ",
  " تلاشها و نقطه عطف خود را جشن بگیرید! ",
  " Прославете ги вашите напори и пресвртница! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Whakanuia o Mahi Kairangi! ",
  " Feire din innsats og milepæl! ",
  " Celebrate Your Efforts and Milestone's! ",
  " خپلې هڅې او هیلې ولمانځئ! ",
  " Feiert Är Efforten a Meilesteen! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Świętuj swoje wysiłki i kamień milowy! ",
  " Comemore seus esforços e marcos! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Sărbătorește-ți eforturile și reperul! ",
  " Отметьте свои усилия и веху! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Faʻamanatu au taumafaiga ma mea taua! ",
  " Ku dabbaal dadaalkiinna iyo waxaaga qaaliga ah! ",
  " Celebrate Your Efforts and Milestone's! ",
  "¡Celebre sus esfuerzos e hitos! ",
  " Ngagungkeun usaha anjeun sareng tonggak sejarah! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Fira dina ansträngningar och milstolpe! ",
  " Ҷидду ҷаҳди худ ва марҳилаи муҳимро ҷашн гиред! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Çabalarınızı ve Dönüm Noktasını Kutlayın! ",
  " Zähmetiňizi we ädimiňizi belläň! ",
  " Celebrate Your Efforts and Milestone's! ",
  " اپنی کاوشوں اور سنگ میل کو منائیں! ",
  " Kỷ niệm những nỗ lực và cột mốc quan trọng của bạn! ",
  " Celebrate Your Efforts and Milestone's! ",
  " פייַערן דיין השתדלות און מילעסטאָנע! ",
  " Ospatu zure ahalegina eta mugarria! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Proslavite svoje napore i prekretnice! ",
  " Celebra els teus esforços i fites! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Pagsaulog sa Imong Mga Paningkamot ug Milyahe! ",
  " Sangalalani ndi Khama Lanu ndi Mbiri Yanu! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Festighjate i vostri sforzi è u Milestone's! ",
  " Vier uw inspanningen en mijlpaal! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Festu Viajn Klopodojn kaj Mejloŝtonon! ",
  " Juhli ponnistelujasi ja virstanpylväs! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Fier jo ynspannings en mielstien! ",
  " Selebre efò ou ak etap enpòtan! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Kiyaye Youroƙarinku da Babban Abinku! ",
  " Hoʻolauleʻa i kāu mau hana a me kāu milestone! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ua kev zoo siab rau koj lub dag zog thiab Milestone's! ",
  " Na-agba mbọ maka mbọ ị na-agba! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ngrameke gaweyan lan tonggak sejarah! ",
  " សូមអបអរសាទរការខិតខំប្រឹងប្រែងនិងសារៈសំខាន់របស់អ្នក! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Nuhimbaze Imbaraga zawe nintambwe! ",
  " Hewldan û Mîlada Xwe Pîroz Bikin! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Аракетиңизди жана Эң Чың Мааракеңизди белгилеңиз! ",
  " ຊົມເຊີຍຄວາມພະຍາຍາມແລະຄວາມ ສຳ ຄັນຂອງທ່ານ! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Sviniet savus centienus un pagrieziena punktu! ",
  " Švęskite savo pastangas ir etapą! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Mankalazà ny ezaka ataonao sy ny tanjonao! ",
  " Raikan Usaha dan Tonggak Anda! ",
  " Celebrate Your Efforts and Milestone's! ",
  " നിങ്ങളുടെ ശ്രമങ്ങളും നാഴികക്കല്ലും ആഘോഷിക്കൂ! ",
  " Iċċelebra l-Isforzi u l-Milestone's Tiegħek! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Whakanuia o Mahi Kairangi! ",
  " आपले प्रयत्न आणि मैलाचा दगड साजरा करा! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ṣe ayẹyẹ awọn ipa ati ibi-iṣẹlẹ rẹ! ",
  " သင်၏ကြိုးပမ်းမှုများနှင့်သမိုင်းမှတ်တိုင်ကိုကျင်းပပါ။",
  " Celebrate Your Efforts and Milestone's! ",
  " तपाइँको प्रयास र माइलस्टोन मनाउनुहोस्! ",
  " Feire din innsats og milepæl! ",
  " Celebrate Your Efforts and Milestone's! ",
  " ତୁମର ପ୍ରୟାସ ଏବଂ ମାଇଲଷ୍ଟୋନ୍ ପାଳନ କର! ",
  " Świętuj swoje wysiłki i kamień milowy! ",
  " Celebrate Your Efforts and Milestone's! ",
  " ਆਪਣੇ ਯਤਨਾਂ ਅਤੇ ਪੱਥਰ ਦਾ ਜਸ਼ਨ ਮਨਾਓ! ",
  " Прославите своје напоре и прекретнице! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Keteka Boiteko ba Hau le Ketsahalo ea Bohlokoa! ",
  " Pemberera Kuedza Kwako uye Chiitiko! ",
  " Celebrate Your Efforts and Milestone's! ",
  " توهان جي ڪوشش ۽ سنگ ميل جو جشن ڪريو! ",
  " ඔබේ උත්සාහය හා සන්ධිස්ථානය සමරන්න! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Proslavite svoja prizadevanja in svoj mejnik! ",
  " Oslávte svoje úsilie a svoj míľnik! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Ngagungkeun usaha anjeun sareng tonggak sejarah anjeun! ",
  " Sherehekea juhudi zako na hatua yako muhimu! ",
  " Celebrate Your Efforts and Milestone's! ",
  " U dabbaaldeg dadaalladaada iyo guusha aad gaadhay! ",
  " Bhiyozela iinzame zakho kunye nembalasane! ",

  " Celebrate Your Efforts and Milestone's! ",
  " เฉลิมฉลองความพยายามและความสำเร็จของคุณ! ",
  " Відзначте свої зусилля та віху! ",
  " Celebrate Your Efforts and Milestone's! ",
  "تىرىشچانلىقىڭىز ۋە ئابىدىڭىزنى تەبرىكلەڭ ! ",
  " Sizning harakatlaringiz va muhim bosqichingizni nishonlang! ",
  " Celebrate Your Efforts and Milestone's! ",
  " Dathlwch eich ymdrechion a‘ch carreg filltir! ",
];

function Banner() {
  const [searchValue, setSearchValue] = useState("");
  const [index, setIndex] = React.useState(0);

  const { history } = useReactRouter();

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      //Enter key pressed
      search();
    }
  };
  useEffect(() => {
    let id = setInterval(() => setIndex((index) => index + 1), 3000);

    return () => {
      clearInterval(id);
    };
  }, []);
  const search = () => {
    if (!searchValue) {
      return document.getElementById("searchBox").focus();
    }
    history.push(`/search/${searchValue}`);
  };
  return (
    <section className="hero is-fullheight-with-navbar">
      <div className="hero-body banner-text">
        <div className="container banner-contents has-text-centered">
          <img className="banner-logo" alt="Brieft Logo" src={logo} />
        </div>
        <TextTransition
          className={"banner-text"}
          text={TEXTS[index % TEXTS.length]}
          springConfig={presets.wobbly}
        />
        <div className=" banner-contents has-text-centered">
          <div className="field has-addons">
            <p className="control is-expanded">
              <input
                id="searchBox"
                className="input is-rounded search-bar"
                type="text"
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyDown={handleKeyPress}
              />
            </p>
            <p className="control">
              <button
                className="button is-rounded is-info go-button"
                onClick={search}
              >
                Go
              </button>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
