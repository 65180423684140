export default class Utilities {
  static userName_inital = "brieft";

  static getRandomTime = () => {
    return new Date().getUTCMilliseconds();
  };

  static getUniqueUserName = (firstname, lastname, currentVal) => {
    //console.log("currentVal >>>", currentVal);
    //const uniqueUsername=emailvalue+Math.random().toString(36).substr(2, 3)+lastName.substr(0, 1)+ Math.random().toString(36).substr(2, 4);
    //${Utilities.userName_inital}_

    if (firstname !== "" && lastname !== "") {
      const checkfirstnamespace =
        firstname.indexOf(" ") >= 0
          ? firstname.replace(" ", "")
          : firstname.trim();

      return `${checkfirstnamespace}${lastname.trim()}${currentVal}`;
    } else if (firstname === "" && lastname !== "") {
      return `${lastname.trim()}${currentVal}`;
    } else if (firstname !== "" && lastname === "") {
      const checkspace =
        firstname.indexOf(" ") >= 0
          ? firstname.replace(" ", "")
          : firstname.trim();
      return `${checkspace}${lastname.trim()}${currentVal}`;
    } else {
      return "";
    }

    //return `${checkspace}${lastname}${Utilities.getRandomTime()}${Math.random()
    //  .toString(36) .substr(1, 1)}`;
  };
}
