import React, { useState, useContext } from "react";
// import DatePicker from "react-datepicker";
// import Dropdown from 'react-dropdown';
//import useReactRouter from 'use-react-router';
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";

import { Store } from "../Store";
import { database } from "../stitch";
import { randomId } from "../utils";
import { ObjectId } from "bson";

const options = [
  { value: "MMMM d, yyyy", label: "Month, Day and Year" },
  { value: "d", label: "Only Day" },
  { value: "yyyy", label: "Only Year" },
  { value: "MMMM", label: "Only Month" },
  { value: "MMMM, yyyy", label: "Month and Year" },
  { value: "MMMM d", label: "Month and Day" },
  { value: "d, yyyy", label: "Day and Year" },
];
const defaultOption = options[0].value;
function PostMessageModal({ onClose, isMyProfile, activity }) {
  // let {
  // 	match: {
  // 		params: { id },
  // 	},
  // } = useReactRouter();

  const [text, setText] = useState((activity && activity.text) || "");
  const [msgDate] = useState(new Date()); //(activity && new Date(activity.activityDate)) ||
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [format] = useState(defaultOption);
  const { profile, setStore } = useContext(Store);

  const SavePostMessage = async (e) => {
    e.preventDefault();
    if (!validateText(text)) {
      setError("Text cannot be empty");
      return;
    }
    const newActivity = { msgDate: msgDate.toString(), text, format: format, senderid: profile._id }; //senderid: profile._id, };
    setLoading(true);
    newActivity.id = randomId();

    await database
      .collection("profiles")
      .updateOne(
        { _id: ObjectId(profile._id) },
        { $push: { timelineMsg: newActivity } }
      );

    if (!profile?.timelineMsg) profile.timelineMsg = [];
    profile.timelineMsg.push(newActivity);

    setLoading(false);
    setStore("profile", profile);
    onClose();
  };

  const validateText = (value) => {
    if (value) return true;
    return false;
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <form className="newedit" onSubmit={SavePostMessage}>
        <fieldset disabled={loading}>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Add Message</p>
              <button
                className="delete"
                aria-label="close"
                onClick={onClose}
              ></button>
            </header>
            <section className="modal-card-body">
              {error && <div className="notification is-danger">{error}</div>}

              {/* <div className="field">
                <label className="label">Date</label>
                <Dropdown options={options} onChange={(e)=>setDateFormat(e.value)} value={defaultOption} placeholder="Select an option" />

                <div className="control">
                  <DatePicker
                    required
                    className="input date-input"
                    selected={msgDate}
                    onChange={(value) => setMsgDate(value)}
                    showDateSelect
                    dateFormat={format}
                    showYearPicker={format==='yyyy' ? true: false}
                    showMonthYearPicker={format==='MMMM, yyyy' || format==='MMMM'? true:false}
                    ></DatePicker>

                </div>
              </div> */}
              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    required
                    maxLength={300}
                    placeholder="Write your Message."
                    className="textarea"
                    type="text"
                    value={text}
                    rows="6"
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
                <p>{`${300 - text.length} characters left.`}</p>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button
                type="submit"
                className={`button is-rounded is-info ${
                  loading ? "is-loading" : ""
                }`}
              >
                Post
              </button>
              <button className="button is-rounded" onClick={onClose}>
                Cancel
              </button>
            </footer>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export default PostMessageModal;
