import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import DummyImage from "./DummyImage.json";
import { database } from "../stitch";
import { ObjectId } from "bson";
import { Store } from "../Store";
import { Link } from "react-router-dom";
import { TryFollow } from "../component/TryFollow";
function FollowingModal({
  isMyProfile,
  onClose,
  selectedProfile,
  setSelectedProfile,
  setIsFollowingShown,
}) {
  const { profile, setStore } = useContext(Store);
  //const [loading, setLoading] = useState(false);
  const { following } = selectedProfile;
  const [search, setSearch] = useState(" ");
  const [searchResults, setSearchResults] = useState(
    following.length > 0 ? following : []
  );
  const [enableTryModal, setEnableTryModal] = useState(false);
  const [tryUserName, setTryUserName] = useState("");
  // const [followings, setFollowings] = useState(false);
  //console.log("selectedProfile >>>", selectedProfile);
  const isUserFollowing = (userObj) => {
    if (profile && profile.following) {
      for (const item of profile.following) {
        if (item?.userid?.toString() === userObj?.userid?.toString()) {
          return true; // User is following
        }
      }
    }
    return false; // User is not following
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);
    setTimeout(() => {
      if (search.length > 0) {
        const filteredResults = following.filter(
          (item) =>
            item.name.toLowerCase().includes(search.toLowerCase()) ||
            item.userName.toLowerCase().includes(search.toLowerCase())
        );

        setSearchResults(filteredResults);
      } else {
        setSearchResults(following);
      }
    }, 500);
  };

  // const profileFollowLableHandler = (dataObj) => {
  //   //console.log("dataObj >>", dataObj);
  //   return profile?.following?.length > 0 &&
  //     profile?.following?.filter(
  //       (item) => item.userid.toString() === dataObj?.userid.toString()
  //     ).length > 0
  //     ? "Following"
  //     : "Follow";
  // };

  const FollowingClickHandler = async (userObj) => {
    if (profile) {
      const tempObj = profile?.following?.filter(
        (item) => item.userid?.toString() !== userObj?.userid?.toString()
      );
      profile.following = tempObj;
      selectedProfile.following = tempObj;

      await database
        .collection("profiles")
        .updateOne(
          { _id: ObjectId(profile._id) },
          { $set: { following: tempObj } }
        );
      await database
        .collection("profiles")
        .updateOne(
          { _id: ObjectId(userObj.userid) },
          { $pull: { followers: { userid: ObjectId(profile._id) } } }
        );
      setSelectedProfile(selectedProfile);
      setSearchResults(selectedProfile.following);
      setStore(profile);
      // setFollowings(true);
    } else {
      //alert("Please Sign in / Sign up to your account to access profile.");
      const tempusernme = userObj ? userObj.name : "";
      //console.log("tempusernme >((()))>>>", userObj, tempusernme);
      setEnableTryModal(true);
      setTryUserName(tempusernme);

      return false;
    }
  };

  const FollowingFollowerClickHandler = async (userObj) => {
    if (profile) {
      const isFollowing = profile?.following?.some(
        (item) => item.userid?.toString() === userObj.userid?.toString()
      );
      if (isFollowing) {
        // Unfollow user
        const tempObj = profile?.following?.filter(
          (item) => item.userid?.toString() !== userObj.userid?.toString()
        );

        profile.following = tempObj;
        selectedProfile.following = tempObj;

        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(profile._id) },
            { $set: { following: tempObj } }
          );
        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(userObj.userid) },
            { $pull: { followers: { userid: ObjectId(profile._id) } } }
          );
      } else {
        // Follow user If is not follow
        const newFollowing = [...profile.following, userObj];
        profile.following = newFollowing;
        selectedProfile.following = newFollowing;

        await database
          .collection("profiles")
          .updateOne(
            { _id: ObjectId(profile._id) },
            { $set: { following: newFollowing } }
          );
        await database.collection("profiles").updateOne(
          { _id: ObjectId(userObj.userid) },
          {
            $push: {
              followers: {
                userid: ObjectId(profile._id),
                name: profile.firstName,
                userName: profile.userName,
                image: profile.image,
                createat: new Date(),
              },
            },
          }
        );
      }

      setSelectedProfile(selectedProfile);
      // setSearchResults(selectedProfile.following);
      setStore(profile);
    } else {
      //alert("Please Sign in / Sign up to your account to access profile.");
      const tempusernme = userObj && userObj.name;

      //console.log("tempusernme >((()))>>25>", userObj, tempusernme);
      setEnableTryModal(true);
      setTryUserName(tempusernme);
      return false;
    }
  };

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-weight-bold">
              Following User
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={onClose}
            ></button>
          </header>
          <section className="modal-card-body">
            <p className="control has-icons-left">
              <input
                onChange={handleSearchChange}
                className="input"
                type="text"
                placeholder="Search"
              />
              <span className="icon is-left">
                <i className="fas fa-search" aria-hidden="true"></i>
              </span>
            </p>
            <div className="following-list">
              {searchResults &&
                searchResults?.map((item, index) => {
                  const btnlabl =
                    profile?.following?.length > 0 &&
                    profile?.following?.filter(
                      (itm) =>
                        itm.userid?.toString() === item?.userid?.toString()
                    ).length > 0
                      ? "Following"
                      : "Follow";

                  // console.log("btnlabl >>", btnlabl);
                  // const image = `data:image/png;base64,${
                  //   item.image || DummyImage.data
                  // }`;

                  const images =
                    item.image === ""
                      ? `data:image/png;base64, ${DummyImage.data}`
                      : `data:image/png;base64, ${item.image}`;

                  return (
                    <div
                      key={index}
                      //className="is-flex is-justify-content-space-between is-align-content-center "
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                      }}
                    >
                      <Link
                        to={{
                          pathname: `/${item.userName}`,
                          state: { id: item.userid },
                        }}
                        //to={`/${item.userName}`}
                      >
                        <div
                          onClick={(e) => setIsFollowingShown(false)}
                          className="profile-image"
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <img
                            src={images}
                            alt=""
                            style={{
                              borderRadius: "50%",
                              height: "46px",
                              width: "46px",
                            }}
                          />
                          <div
                            className="profile-meta"
                            style={{ marginLeft: "10px" }}
                          >
                            <p className="has-text-weight-bold has-text-black wordwapUserName">
                              {item.name}
                            </p>
                            <p className="has-text-black wordwapUserName">
                              {item?.userName}
                            </p>
                          </div>
                        </div>
                      </Link>
                      {profile &&
                      profile?._id?.toString() === item.userid?.toString() ? (
                        <></>
                      ) : (
                        <>
                          {isMyProfile ? (
                            <button
                              onClick={() => FollowingClickHandler(item)}
                              className={`${
                                btnlabl === "following"
                                  ? "button is-dark has-text-weight-bold"
                                  : "button postMessagebg has-text-weight-bold"
                              }`}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              {btnlabl}
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                FollowingFollowerClickHandler(item)
                              }
                              className={`${
                                isUserFollowing(item)
                                  ? "button is-dark has-text-weight-bold"
                                  : "button postMessagebg has-text-weight-bold"
                              }`}
                              style={{ marginRight: "10px", cursor: "pointer" }}
                            >
                              {isUserFollowing(item) ? "Following" : "Follow"}
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </div>
      {enableTryModal && (
        <TryFollow
          enableTryModal={enableTryModal}
          setEnableTryModal={setEnableTryModal}
          userName={tryUserName}
          setTryUserName={setTryUserName}
        />
      )}
    </>
  );
}

export default FollowingModal;
