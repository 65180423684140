import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ResetPasswordBody from '../components/ResetPasswordBody';

function ResetPassword() {
  return (
    <>
      <Header />
      <div className="page-body"><ResetPasswordBody /></div>
      <Footer />
    </>
  );
}

export default ResetPassword;