import React, { useEffect, useRef, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function ImageComponent({ filename }) {
  //console.log("filename Image Component: ", filename)
  const isMounted = useRef(true);
  const [fileurl, setFileurl] = useState("");

  const getFileURL = async (filename) => {
    //console.log("filename: ", filename)
    //const storage = getStorage(firebaseApp, 'gs://ibrieft-2c6f5.appspot.com');//getStorage();
    // Points to the root reference
    const storage = getStorage();
    const starsRef = ref(storage, filename);
    // const storageRef = ref(storage);
    const downloadurl = await getDownloadURL(starsRef);

    setFileurl(downloadurl);
    // console.log("downloadurl: ", downloadurl);
    // return downloadurl;
  };

  useEffect(() => {
    isMounted.current = true;
    getFileURL(filename);
    return () => {
      isMounted.current = false;
    };
  }, [filename]);

  return (
    <img className="is-150x150" src={fileurl} height={250} width={250} alt="" />
  );
}

export default ImageComponent;
