import React from 'react';
import PostMessage from '../components/PostMessage';
import Header from '../components/Header';
import Footer from '../components/Footer';

function PostMessageView() {
  return (
    <>
      <Header />
      <div className="page-body"><PostMessage /></div>
      <Footer />
    </>
  );
}

export default PostMessageView;